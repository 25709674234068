require('./plugins/bootstrap');
require('./plugins/moment');
require('./plugins/permissions');
require('./plugins/firebase');
require('moment/locale/es');
require('./plugins/apexchart');
require('./plugins/leaflet');
require('./plugins/eventbus');

import Vue from 'vue'
// import Axios from 'axios'

// Vue.prototype.$http = Axios;
// import { mapGetters, mapActions } from 'vuex'
// import AuthMixin from '@/plugins/authMixin';
// Vue.use(AuthMixin);
import App from '@/App.vue'
import vuetify from '@/plugins/vuetify'
import router from '@/plugins/routes'
import store from '@/store/index'
// import mixin from '@/plugins/interceptorsMixin'
// import authInterceptorMixin from '@/plugins/authInterceptorMixin';
// import sucursalInterceptorMixin from '@/plugins/sucursalInterceptorMixin';

// Vue.mixin(authInterceptorMixin);
// // Agrega el mixin a la instancia de Vue
// Vue.mixin(sucursalInterceptorMixin);
// Vue.mixin(mixin)
export default new Vue({
    el: '#app',
    vuetify: vuetify,
    router: router,
    store: store,
    render: h => h(App),
    // Agrega el bus de eventos como una propiedad global para que esté disponible en toda la aplicación
    // provide: {
    //   eventbus
    // }
  });