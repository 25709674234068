var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-sheet',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.collection,"search":_vm.search,"single-select":"","show-select":false,"footer-props":{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      },"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.itemsLength,"items-per-page":15,"disable-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.onBack.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" "),_c('div',[_vm._v("Volver")])],1)],1)]},proxy:true},{key:"item.updated_at",fn:function(ref){
      var item = ref.item;
return [(item.updated_at)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("moment")(item.updated_at,'DD/MM/YYYY HH:mm'))+"\n        ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [(item.created_at)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("moment")(item.created_at,'DD/MM/YYYY HH:mm'))+"\n        ")]):_vm._e()]}},{key:"item.published_at",fn:function(ref){
      var item = ref.item;
return [(item.published_at)?_c('div',[_vm._v("\n          "+_vm._s(_vm._f("moment")(item.published_at,'DD/MM/YYYY'))+"\n        ")]):_vm._e()]}},{key:"item.observaciones",fn:function(ref){
      var item = ref.item;
return [(item.obs_estado)?_c('div',[_vm._v("\n          "+_vm._s(item.obs_estado)+"\n        ")]):(item.obs_chofer)?_c('div',[_vm._v("\n          "+_vm._s(item.obs_chofer)+"\n        ")]):(item.observaciones)?_c('div',[_vm._v("\n          "+_vm._s(item.observaciones)+"\n        ")]):_vm._e()]}},{key:"item.estado",fn:function(ref){
      var item = ref.item;
return [(item.estado)?_c('div',[_vm._v("\n          "+_vm._s(item.estado.nombre)+"\n        ")]):_vm._e()]}},{key:"item.chofer",fn:function(ref){
      var item = ref.item;
return [(item.chofer)?_c('div',[_vm._v("\n          "+_vm._s(item.chofer.nombre + ' ' + item.chofer.apellido)+"\n        ")]):_c('div',[_vm._v("Sin asignar")])]}},{key:"item.autor",fn:function(ref){
      var item = ref.item;
return [(item.autor)?_c('div',[_vm._v("\n          "+_vm._s(item.autor.name)+"\n        ")]):_vm._e()]}},{key:"item.domicilio.cliente",fn:function(ref){
      var item = ref.item;
return [(item.domicilio.cliente)?_c('div',[_vm._v("\n          "+_vm._s(item.domicilio.cliente.razon_social)+"\n        ")]):_vm._e()]}},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"error","icon":"mdi-alert"}},[_vm._v("\n          No se ha encontrado ningún resultado para \""+_vm._s(_vm.search)+"\".\n        ")])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"warning","icon":"mdi-alert"}},[_vm._v("\n          No hay información de ordenes para mostrar.\n        ")])]},proxy:true},{key:"pageText",fn:function(props){return [_vm._v("\n        Filas "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+"\n      ")]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }