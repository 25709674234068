<template>
  <div>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="collection"
      :search="search"
      single-select
      show-select
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
    >
      <template v-slot:top>
        <v-toolbar flat dark extended :color="colorToolbar">
          <v-text-field
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Buscar"
            flat
            solo-inverted
            hide-details
            clearable
          ></v-text-field>

          <template v-slot:extension>
            <v-scroll-x-reverse-transition>
              <v-btn
                v-if="selected.length && $can('estados.update')"
                :disabled="selected.length > 1"
                text
                color="warning"
                @click="onEdit(selected)"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-pencil</v-icon>
                <div class="hidden-xs-only">Editar</div>
              </v-btn>
            </v-scroll-x-reverse-transition>
            <v-scroll-x-reverse-transition>
              <v-btn
                v-if="selected.length && $can('estados.destroy')"
                text
                color="error"
                @click="onDelete(selected)"
              >
                <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-delete</v-icon>
                <div class="hidden-xs-only">Borrar</div>
              </v-btn>
            </v-scroll-x-reverse-transition>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="onCreate"
              v-if="$can('estados.store')"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-plus</v-icon>
              <div class="hidden-xs-only">Agregar</div>
            </v-btn>
          </template>
        </v-toolbar>
      </template>
      <template v-slot:item.color="{ item }">
        <v-avatar
          size="22"
          :style="'background-color:' + item.color"
        >
        </v-avatar>
      </template>
      <template v-slot:item.icono="{ item }">
        <v-icon
          v-if="item.icono"
          v-html="item.icono"
          :style="'color:' + item.color"
        >
        </v-icon>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de estados para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      title: 'Listado de estados',
      search: '',
      selected: [],
    }
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters({
      collection: 'Estados/all',
      headers: 'Estados/headers',
      loading: 'Estados/loading',
      pagination: 'Estados/pagination',
      itemsLength: 'Estados/itemsLength',
      optionsGetter: 'Estados/options',
    }),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    colorToolbar () {
      return this.selected.length ? 'listSelected' : 'toolbarList'
    },
  },
  methods: {
    onCreate () {
      this.$router.push({ name: 'estados-create' })
    },
    onEdit (selected) {
      this.$router.push({ name: 'estados-edit', params: { id: selected[0].id }})
    },
    onDelete (selected) {
      this.$router.push({ name: 'estados-delete', params: { id: selected[0].id }})
    },
    ...mapActions({
      onFetch: 'Estados/onFetch',
    }),
    ...mapMutations({
      setOptions: 'Estados/setOptions',
      setSelected: 'Estados/setSelected',
    }),
  }
}
</script>
