<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      :width="400"
    >
      <template v-slot:prepend>
        <v-toolbar color="primary"></v-toolbar>
      </template>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-list>
          <v-subheader>Datos del cliente</v-subheader>
          <v-list-item>
            <v-text-field
              v-model="documento"
              placeholder="Ingrese un número"
              label="Número"
              :rules="rules.documento"
              prepend-icon="mdi-counter"
              color="primary"
              clearable
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-text-field
              v-model="razonSocial"
              placeholder="Ingrese Apellido y nombre"
              label="Apellido y nombre"
              :rules="rules.razon_social"
              prepend-icon="mdi-text"
              color="primary"
            ></v-text-field>
          </v-list-item>
          <v-list-item>
            <v-switch
              v-model="inactivo"
              label="Inactivo"
              color="primary"
            ></v-switch>
          </v-list-item>
        </v-list>
      </v-form>
      <v-list nav>
        <v-divider></v-divider>
        <v-list-item
          @click="onCancel"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Volver</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-table-star</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          @click="onSubmit"
          class="primary"
          dark
          :disabled="editedAndSelectedEquals || loading"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Actualizar cliente</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-check</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <!--
        <v-list-item
          :to="{ name: 'clientes-datatable' }"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Clientes</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>
        </v-list-item>
        -->
        <v-list-item
          @click="dialogDelete = !dialogDelete"
        >
          <v-list-item-icon></v-list-item-icon>
          <v-list-item-title>Eliminar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-delete</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <my-cliente-delete
      v-model="dialogDelete"
      :cliente-id="id"
    ></my-cliente-delete>
    <v-sheet>
      <v-container
        v-if="domicilioEdited && domicilioEdited.id"
      >
        <my-domicilio-edit
        ></my-domicilio-edit>
      </v-container>
      <v-container
        v-else
      >
        <my-domicilio-create
          :reset="resetValidation"
        ></my-domicilio-create>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-sheet>
      <v-container>
        <my-domicilios-datatable></my-domicilios-datatable>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import DomicilioCreate from '@/pages/domicilios/Create'
import DomicilioEdit from '@/pages/domicilios/Edit'
import DomiciliosDatatable from '@/pages/domicilios/Datatable'
import ClientesDelete from '@/pages/clientes/Delete'
export default {
  components: {
    'my-domicilio-create': DomicilioCreate,
    'my-domicilio-edit': DomicilioEdit,
    'my-domicilios-datatable': DomiciliosDatatable,
    'my-cliente-delete': ClientesDelete,
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
      dialogDelete: false,
      resetValidation: false,
    }
  },
  // created () {
  //   this.onClearEditedDomicilio()
  //   this.onClearSelectedDomicilio()
  // },
  beforeMount () {
    this.onClearEditedDomicilio()
    this.onClearSelectedDomicilio()
    this.onClearAllDomicilios()
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy () {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    this.onClearEdited()
    this.onClearSelected()
    if (this.$route.params.id) {
      this.setFilters({
        ...this.filters,
        cliente_id: this.$route.params.id
      })
      // this.setFiltersDomicilios({
      //   ...this.domicilioFilters,
      //   cliente_id: this.$route.params.id
      // })
      this.onFind()
    }
  },
  computed: {
    ...mapGetters({
      domicilioEdited: 'Domicilios/edited',
      domicilioSelected: 'Domicilios/selected',
      // domicilioFilters: 'Domicilios/filters'
    }),
    ...mapGetters('Clientes', ['edited', 'rules', 'loading', 'editedAndSelectedEquals']),
    id () {
      return this.edited.id
    },
    documento: {
      get () {
        return this.edited.documento
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          documento: value
        })
      }
    },
    razonSocial: {
      get () {
        return this.edited.razon_social
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          razon_social: value
        })
      }
    },
    inactivo: {
      get () {
        return this.edited.inactivo ? true : false
      },
      set (value) {
        const now = new Date()
        var inactivoAux = null
        if (value === true) inactivoAux = this.$moment().format('YYYY-MM-DD')
        this.setEdited({
          ...this.edited,
          inactivo: inactivoAux
        })
      }
    },
    domicilio () {
      return this.edited.domicilio
    }
  },
  methods: {
    ...mapActions({
      onUpdate: 'Clientes/onUpdate',
      onFind: 'Clientes/onFind',
      onClearEdited: 'Clientes/onClearEdited',
      onClearSelected: 'Clientes/onClearSelected',
    }),
    ...mapActions({
      onClearEditedDomicilio: 'Domicilios/onClearEdited',
      onClearSelectedDomicilio: 'Domicilios/onClearSelected',
    }),
    ...mapMutations('Clientes', ['setEdited', 'setFilters']),
    ...mapMutations({
      setAllDocumentos: 'Domicilios/setAll'
    }),
    onCancel () {
      this.$router.go(-1)
    },
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid && !this.editedAndSelectedEquals) {
        this.onUpdate()
      }
    },
    onClearAllDomicilios () {
      this.setAllDocumentos([])
    },
    handleKeyboard (event) {
      if (!this.loading) {
        if (event.key === 'F1') {
          event.preventDefault()
          this.onCancel()
        }
        else if (event.key === 'F2') {
          this.onSubmit(event)
        }
      }

    },
  },
}
</script>
