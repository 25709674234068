<template>
  <div>
    <v-alert
      v-model="snackbar"
      :type="type"
      border="bottom"
      elevation="12"
    >
      <template v-slot:append>
        <v-progress-circular
          :rotate="-90"
          :value="countDown"
          :size="30"
        >
          <v-icon
            size="20"
            @click.prevent="snackbar = !snackbar"
          >
            mdi-close
          </v-icon>
        </v-progress-circular>
      </template>
      <div>
        {{ text }}
      </div>
    </v-alert>
    <!-- <v-snackbar
      v-model="snackbar"
      :color="color"
      top
      absolute
      :timeout="10000"
      elevation="12"
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-icon
          color="white"
          v-bind="attrs"
          @click="onViewed"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar> -->
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {

  data () {
    return {
      snackbar: false,
      text: '',
      color: 'primary',
      type: 'success',
      countDown: 100,
    }
  },

  created () {
    this.countDownTimer()
  },

  computed: {
    ...mapGetters({
      recent: 'ResponseHTTP/recent',
    }),
  },

  watch: {
    recent (value) {
      if (!_.isNil(value)) {
        this.text = value.title
        this.color = value.color
        this.type = value.type
        this.countDown = 100
        this.snackbar = true
        this.countDownTimer()
      } else {
        this.text = ''
        this.countDown = 0
        this.snackbar = false
      }
    },
  },

  methods: {
    ...mapMutations({
      onViewed: 'ResponseHTTP/viewed',
    }),

    onSubmit () {
      onViewed()
      this.snackbar = false
    },

    countDownTimer() {
      if(this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 5
          this.countDownTimer()
        }, 1000)
      } else {
        this.snackbar = false
      }
    },
  },
}
</script>