<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      width="400"
    >
      <template v-slot:prepend>
        <v-list-item class="primary" dark>
          <v-list-item-title>Reasignar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-truck-trailer</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </template>
      <v-form
        ref="form"
        v-model="valid">
        <v-list nav>
          <v-subheader>Ordenes filtradas por:</v-subheader>
          <my-chofer-select
            v-model="chofer"
          ></my-chofer-select>
          <!--
          <v-list-item>
          -->
            <v-text-field
              label="Estado"
              value="PENDIENTE"
              prepend-icon="mdi-truck-fast"
              readonly>
            </v-text-field>
          <!--
          </v-list-item>
          <v-list-item>
          -->
            <my-date-picker
              label="Publicado"
              v-model="published"
            ></my-date-picker>
          <!--
          </v-list-item>
          -->
          <v-divider></v-divider>
          <v-subheader>Reasignar al chofer:</v-subheader>
          <my-chofer-select
            v-model="choferNuevo"
            :excepto="chofer"
            :rules="rules.chofer_id_nuevo"
          ></my-chofer-select>
          <v-list-item
            @click.stop="onConfirmar"
            class="primary"
            dark
          >
            <v-list-item-avatar>F2</v-list-item-avatar>
            <v-list-item-title>Confirmar</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-check</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list nav>
          <v-list-item
            @click="onCancel"
          >
            <v-list-item-avatar>F1</v-list-item-avatar>
            <v-list-item-title>Ordenes</v-list-item-title>
            <v-list-item-icon>
              <v-icon>mdi-table-star</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
      </v-form>
    </v-navigation-drawer>
    <v-toolbar flat dense>
      <v-subheader>Ordenes de distribución disponibles para reasignar</v-subheader>
    </v-toolbar>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="collection"
      :search="search"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="100"
      disable-sort
      show-select
    >
      <template v-slot:item.domicilio.cliente="{ item }">
        <div>{{ item.domicilio.cliente.razon_social }}</div>
      </template>
      <template v-slot:item.domicilio.descripcion="{ item }">
        <div>{{ item.domicilio.descripcion }}</div>
        <div>{{ item.domicilio.observaciones }}</div>
      </template>
      <template v-slot:item.created_at="{ item }">
        <div v-if="item.created_at">
          <div v-if="monthDescription">
            {{ item.created_at | moment(momentFormat) }}
          </div>
          <div v-else>
            {{ item.created_at | moment('dddd, DD/MM') }}
          </div>
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de ordenes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ChoferSelect from '@/pages/choferes/Select'
import DatePicker from '@/components/DatePicker'
import EstadoSelect from '@/pages/estados/Select'

export default {
  components: {
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker,
    'my-estado-select': EstadoSelect,
  },
  data () {
    return {
      valid: true,
      search: '',
      monthDescription: false,
      momentFormat: '',
      today: null,
      yesterday: null,
      lastYear: null,
      dialog: false,
      panelChofer: 0,
      selectedLocal: []
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  created () {
    if (this.$route.params.id) {
      this.setFiltersChoferId(parseInt(this.$route.params.id))
      this.setFiltersSinAsignar(null)
      this.setFiltersEstadoId(1)
      this.setFiltersPublishedAt(this.$moment().format('YYYY-MM-DD'))
      this.setPage(1)
      // this.onFetch()
    }
  },
  computed: {
    ...mapGetters({
      collection: 'OrdenesReasignar/all',
      ordenesSelected: 'OrdenesReasignar/selected',
      filters: 'OrdenesReasignar/filters',
      headers: 'OrdenesReasignar/headers',
      loading: 'OrdenesReasignar/loading',
      itemsLength: 'OrdenesReasignar/itemsLength',
      choferIdNuevo: 'OrdenesReasignar/choferIdNuevo',
      optionsGetter: 'OrdenesReasignar/options',
      sortList: 'OrdenesReasignar/sortList',
      sort: 'OrdenesReasignar/sort',
      rules: 'OrdenesReasignar/rules'
    }),
    selected: {
      get () {
        return this.selectedLocal
      },
      set (value) {
        this.selectedLocal = value
        this.setSelected(_.map(value, 'id'))
      }
    },
    choferNuevo: {
      get () {
        return this.choferIdNuevo;
      },
      set (value) {
        this.setChoferIdNuevo(value)
      }
    },
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    estado: {
      get () {
        return this.filters.estado_id
      },
      set (value) {
        this.setFiltersEstadoId(value)
      }
    },
    chofer: {
      get () {
        return this.filters.chofer_id
      },
      set (value) {
        this.setFiltersChoferId(value)
        this.setFiltersSinAsignar(null)
        this.setPage(1)
        this.onFetch()
      }
    },
    published: {
      get () {
        return this.filters.published_at
      },
      set (value) {
        this.setFiltersPublishedAt(value)
        this.onFetch()
      }
    },
    sinAsignar: {
      get () {
        return this.filters.sinAsignar
      },
      set (value) {
        if (value) this.setFiltersSinAsignar(true)
        else this.setFiltersSinAsignar()
        this.setFiltersChoferId()
        this.onFetch()
      }
    },
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
    colorToolbar () {
      return this.selected.length ? 'listSelected' : 'toolbarList'
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'OrdenesReasignar/onFetch',
      onEstadoInit: 'Estados/onFetch',
      onSave: 'OrdenesReasignar/onSave',
      onAddSelected: 'OrdenesReasignar/onAddSelected',
    }),
    ...mapMutations({
      setSelected: 'OrdenesReasignar/setSelected',
      setOptions: 'OrdenesReasignar/setOptions',
      setPage: 'OrdenesReasignar/setPage',
      setPublishedAt: 'OrdenesReasignar/setPublishedAt',
      setFiltersChoferId: 'OrdenesReasignar/setFiltersChoferId',
      setFiltersEstadoId: 'OrdenesReasignar/setFiltersEstadoId',
      setChoferIdNuevo: 'OrdenesReasignar/setChoferIdNuevo',
      setFiltersPublishedAt: 'OrdenesReasignar/setFiltersPublishedAt',
      setFiltersSinAsignar: 'OrdenesReasignar/setFiltersSinAsignar',
      setSortBy: 'OrdenesReasignar/setSortBy',
      setSortDesc: 'OrdenesReasignar/setSortDesc',
    }),
    onConfirmar () {
      this.$refs.form.validate()
      if (this.ordenesSelected.length > 0 && this.valid) {
        this.onSave().then(response => {
          this.choferNuevo = null
          this.selected = []
          this.$refs.form.resetValidation()
          this.onFetch()
          // this.$router.push({ name: 'ordenes-datatable' })
        })
      } else if (this.ordenesSelected.length == 0) {
        alert('No se ha seleccionado niguna orden')
      }
    },
    onCancel () {
      this.choferNuevo = null
      this.$router.go(-1)
    },
    handleKeyboard(event) {
      if (!this.loading) {
        if (event.key === 'F1') {
          event.preventDefault()
          this.$router.push({ name: 'ordenes-datatable', params: { id: this.chofer } })
        } else if(event.key === 'F2') {
          event.preventDefault()
          this.onConfirmar()
        }
      }
    }
  }
}
</script>
