import Vue from 'vue'
import Router from 'vue-router'
// // LAYOUTS
import MainLayout from '@/pages/layouts/Main'
import OrdenesLayout from '@/pages/layouts/Ordenes'
import ClientesLayout from '@/pages/layouts/Clientes'
import Login from '@/pages/Login'
import Forbidden from '@/pages/Forbidden'
import Unauthorized from '@/pages/Unauthorized'
import CredencialesSesion from '@/pages/sesion/Credenciales'
import PermisosSesion from '@/pages/sesion/Permisos'
// Choferes
import OrdenesEstadisticas from '@/pages/estadisticas/Ordenes'
import ChoferesList from '@/pages/choferes/List'
import ChoferesCreate from '@/pages/choferes/Create'
import ChoferesEdit from '@/pages/choferes/Edit'
import ChoferesDelete from '@/pages/choferes/Delete'
// CLIENTES
import ClientesMain from '@/pages/clientes/Main'
// import ClientesMain from '@/pages/clientes/List'
import ClientesCreate from '@/pages/clientes/Create'
import ClientesForm from '@/pages/clientes/Form'
// ESTADOS
import EstadosList from '@/pages/estados/List'
import EstadosCreate from '@/pages/estados/Create'
import EstadosEdit from '@/pages/estados/Edit'
import EstadosDelete from '@/pages/estados/Delete'
// ORDENES
import OrdenesDataTable from '@/pages/ordenes/Datatable'
import OrdenesMapa from '@/pages/ordenes/Map'
import OrdenesCreate from '@/pages/ordenes/Create'
import OrdenesReasignar from '@/pages/ordenes/Reasignar'
// ORDENES HISTORIAL
import OrdenesHistDatatable from '@/pages/ordenes_hist/DataTable'
// ENVASES
import EnvasesEstadisticas from '@/pages/estadisticas/Movimientos'
import EnvasesDataTable from '@/pages/movimientos/DataTable'
import EnvasesCreate from '@/pages/movimientos/Create'
// ZONAS
import ZonasList from '@/pages/zonas/List'
import ZonasCreate from '@/pages/zonas/Create'
import ZonasEdit from '@/pages/zonas/Edit'
import ZonasDelete from '@/pages/zonas/Delete'
import ErrorsHttp from '@/pages/Errors'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    {
      path: '/unauthorized',
      name: 'unauthorized',
      component: Unauthorized,
    },
    {
      path: '/',
      name: 'layout/clientes',
      // component: AppLayout,
      component: ClientesLayout,
      redirect: '/clientes/main',
      children: [
        {
          path: 'clientes/main',
          name: 'clientes-datatable',
          components: {
            default: ClientesMain,
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.index',
            title: 'Clientes'
          }
        },
      ],
    },
    {
      path: '/',
      name: 'layout/ordenes',
      // component: AppLayout,
      component: OrdenesLayout,
      redirect: '/ordenes/datatable',
      children: [
        {
          path: 'ordenes/datatable',
          name: 'ordenes-datatable',
          components: {
            default: OrdenesDataTable,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.index',
            title: 'Ordenes',
          }
        },
        {
          path: 'ordenes/mapa',
          name: 'ordenes-mapa',
          components: {
            default: OrdenesMapa,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.index',
            title: 'Ordenes',
          }
        },
        {
          path: 'ordeneshist/:id/datatable',
          name: 'ordeneshist-datatable',
          components: {
            default: OrdenesHistDatatable,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.index',
            title: 'Historial'
          }
        },
      ]
    },
    {
      path: '/',
      name: 'layout',
      component: MainLayout,
      // redirect: '/ordenes/datatable',
      children: [
        // ####-####-####-####
        {
          path: 'ordenes/crear',
          name: 'ordenes-create',
          components: {
            default: OrdenesCreate
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.store',
            title: 'Nueva órden de distribución'
          }
        },
        {
          path: 'ordenes/reasignar/:id',
          name: 'ordenes-reasignar',
          components: {
            default: OrdenesReasignar,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.index',
            title: 'Reasignar ordenes de distribución',
          }
        },
        {
          path: 'choferes',
          name: 'choferes-list',
          components: {
            default: ChoferesList,
          },
          meta: {
            requiresAuth: true,
            permission: 'choferes.index',
            title: 'Choferes',
            fluid: true
          }
        },
        {
          path: 'choferes/crear',
          name: 'choferes-create',
          components: {
            default: ChoferesCreate,
          },
          meta: {
            requiresAuth: true,
            permission: 'choferes.store',
            title: 'Nuevo chofer'
          }
        },
        {
          path: 'choferes/editar/:id',
          name: 'choferes-edit',
          components: {
            default: ChoferesEdit,
          },
          meta: {
            requiresAuth: true,
            permission: 'choferes.update',
            title: 'Editar chofer'
          }
        },
        {
          path: 'choferes/borrar/:id',
          name: 'choferes-delete',
          components: {
            default: ChoferesDelete,
          },
          meta: {
            requiresAuth: true,
            permission: 'choferes.destroy',
            title: 'Borrar chofer',
            color: 'delete'
          }
        },
        // ####-####-####-####
        {
          path: 'clientes/form/:id?',
          name: 'clientes-form',
          components: {
            default: ClientesForm,
          },
          meta: {
            requiresAuth: true,
            permission: 'clientes.update',
            title: 'Editar cliente'
          }
        },
        // ####-####-####-####
        {
          path: 'estadisticas/ordenes',
          name: 'estadisticas-ordenes',
          components: {
            default: OrdenesEstadisticas,
          },
          meta: {
            requiresAuth: true,
            permission: 'estados.index',
            title: 'Estadísticas'
          }
        },
        {
          path: 'estadisticas/envases',
          name: 'estadisticas-envases',
          components: {
            default: EnvasesEstadisticas,
          },
          meta: {
            requiresAuth: true,
            permission: 'estados.index',
            title: 'Estadísticas'
          }
        },
        // ####-####-####-####
        {
          path: 'estados',
          name: 'estados-list',
          components: {
            default: EstadosList,
          },
          meta: {
            requiresAuth: true,
            permission: 'estados.index',
            title: 'Estados'
          }
        },
        {
          path: 'estados/crear',
          name: 'estados-create',
          components: {
            default: EstadosCreate,
          },
          meta: {
            requiresAuth: true,
            permission: 'estados.store',
            title: 'Nuevo estado'
          }
        },
        {
          path: 'estados/editar/:id',
          name: 'estados-edit',
          components: {
            default: EstadosEdit,
          },
          meta: {
            requiresAuth: true,
            permission: 'estados.update',
            title: 'Editar estado'
          }
        },
        // {
        //   path: 'estados/borrar/:id',
        //   name: 'estados-delete',
        //   components: {
        //     default: EstadosDelete,
        //   },
        //   meta: {
        //     requiresAuth: true,
        //     permission: 'estados.destroy',
        //     title: 'Borrar estado',
        //     color: 'delete'
        //   }
        // },
        // ####-####-####-####
        {
          path: 'envases/datatable',
          name: 'envases-datatable',
          components: {
            default: EnvasesDataTable,
          },
          meta: {
            requiresAuth: true,
            permission: 'ordenes.index',
            title: 'Envases',
          }
        },
        {
          path: 'envases/crear',
          name: 'envases-create',
          components: {
            default: EnvasesCreate,
          },
          meta: {
            requiresAuth: true,
            // permission: 'envases.store',
            title: 'Nueva movimiento de envases'
          }
        },
        // ####-####-####-####
        {
          path: 'sesion/credenciales',
          name: 'sesion-credenciales',
          components: {
            default: CredencialesSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Credenciales',
            color: 'blue'
          }
        },
        {
          path: 'sesion/permisos',
          name: 'sesion-permisos',
          components: {
            default: PermisosSesion,
          },
          meta: {
            requiresAuth: true,
            title: 'Permisos',
            color: 'blue'
          }
        },
        {
          path: '/forbidden',
          name: 'forbidden',
          component: Forbidden,
        },
        // ####-####-####-####
        {
          path: 'zonas',
          name: 'zonas-list',
          components: {
            default: ZonasList,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.index',
            title: 'Zonas'
          }
        },
        {
          path: 'zonas/crear',
          name: 'zonas-create',
          components: {
            default: ZonasCreate,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.store',
            title: 'Nueva zona'
          }
        },
        {
          path: 'zonas/editar/:id',
          name: 'zonas-edit',
          components: {
            default: ZonasEdit,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.update',
            title: 'Editar zona'
          }
        },
        {
          path: 'zonas/borrar/:id',
          name: 'zonas-delete',
          components: {
            default: ZonasDelete,
          },
          meta: {
            requiresAuth: true,
            permission: 'zonas.destroy',
            title: 'Borrar zona',
            color: 'delete'
          }
        },
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (!to.meta.permission) next()
  else {
    let accessToken = window.sessionStorage.getItem('access_token')
    let permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
    if (permissions.indexOf(to.meta.permission) !== -1) next()
    else if (!accessToken) next({ name: 'login' })
    else next({ name: 'forbidden' })
  }
})

export default router;
