<template>
  <div>
    <v-navigation-drawer
      permanent
      app
      width="300px"
    >
      <v-list nav>
        <!--
        <v-list-item
          v-if="$can('clientes.store')"
          :to="{ name: 'clientes-form' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Cliente nuevo</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        -->
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f4</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('clientes.index')"
          @click="onActualizar"
          :loading="loading"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f5</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
      // display: {
      //   filters: false,
      //   ordering: false,
      // }
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['loading', 'display']),
    showFilters: {
      get () {
        return this.display.filters
      },
      set (value) {
        this.display.filters = value
      }
    },
    showOrdering: {
      get () {
        return this.display.ordering
      },
      set (value) {
        this.display.ordering = value
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    onActualizar (event) {
      event.preventDefault()
      this.onFetch()
    }
  }
}
</script>
