const URL = '/estadisticas';

const state = {
  all: [],
  selected: {},
  found: [],
  labels: [],
  series: [],
  resultados: [],
  todos: [],
  chofer: [],
  filters: {
    chofer_id: null,
    published: {
      from: null,
      to: null
    }
  },
  edited: {},
  loading: false,
}

// GETTERS
const getters = {
  filters (state) {
    return state.filters
  },
  loading (state) {
    return state.loading
  },
  labels (state) {
    return state.labels
  },
  series (state) {
    return state.series
  },
  resultados (state) {
    return state.resultados
  },
  todos (state) {
    return state.todos
  },
  chofer (state) {
    return state.chofer
  },
}

// ACTIONS
const actions = {
  onChoferes ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/choferes', {
          params: {
            chofer_id: state.filters.chofer_id,
            published_from: state.filters.published.from,
            published_to: state.filters.published.to,
          }
        })
        .then(response => {
          commit('setResultados', response.data)
          state.labels = []
          state.series = [ { data: [] }, { data: []} ]
          _.forEach(response.data, (value, key) => {
            state.labels.push(value.label)
            state.series[0].data.push(value.all)
            state.series[1].data.push(value.selected)
          })
          // commit('setTodos', response.data.todos)
          // commit('setSelected', response.data.selected)
          // commit('setLabels', response.data.todos)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setTodos (state, payload) {
    state.todos = Object.assign({}, payload)
  },
  setChofer (state, payload) {
    state.chofer = Object.assign({}, payload)
  },
  setLabels (state, payload) {
    state.chofer = Object.assign({}, payload)
  },
  setFiltersChoferId (state, payload) {
    state.filters.chofer_id = payload
  },
  setFiltersPublishedFrom (state, payload) {
    state.filters.published.from = payload
  },
  setFiltersPublishedTo (state, payload) {
    state.filters.published.to = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setResultados (state, payload) {
    state.resultados = payload
  }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
