<template>
  <div v-if="$can('menu.ordenes.publicado')">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :readonly="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-if="vencido"
          @click.prevent="onSelect(orden)"
          outlined
          v-bind="attrs"
          v-on="on"
          color="red darken-1"
        >
          <v-icon left>mdi-alarm</v-icon>
          {{ label }}{{ selected | moment('dddd, DD/MM') }}
        </v-chip>

        <v-chip
          v-else
          @click.prevent="onSelect(orden)"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon left>mdi-clock-outline</v-icon>
          {{ label }}{{ selected | moment('dddd, DD/MM') }}
        </v-chip>
      </template>
      <v-date-picker
        v-model="selected"
        header-color="primary"
        locale="es-ar"
        @input="menu = false"
        :min="min"
      ></v-date-picker>
    </v-menu>
  </div>
  <div v-else>
    <v-chip
      outlined
    >
      {{ label }}{{ selected | moment('dddd, DD/MM') }}
    </v-chip>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
export default {
  components: {
    'my-date-picker': DatePicker,
  },
  props: {
    orden: {
      type: Object,
      default: null
    },
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    chip: {
      type: Boolean,
      default: false
    },
    minToday: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      placeholder: 'Seleccione un estado',
      dialog: false,
      cliente: false,
      domicilio: false,
      menu: false,
      hoy: new Date()
    }
  },

  created () {
    //
  },

  mounted () {
    //
  },

  computed: {
    ...mapGetters({
      edited: 'Ordenes/edited',
    }),
    color () {
      return 'red darken-1'
    },
    vencido () {
      // return this.orden.estado_id = 1 && this.orden.published_at < this.hoy
      // console.log('this.selected = ' + this.$moment(this.selected))
      // console.log('this.$moment() = ' + this.$moment())
      return this.orden.estado_id == 1 && this.$moment(this.orden.published_at).isBefore(this.$moment(), 'day')
    },
    selected: {
      get () {
        return this.orden.published_at
      },
      set (value) {
        this.setPublishedAt(value)
        this.onSave()
          .then(response => {
            this.menu = false
            this.$emit('refresh', true)
          })
      }
    },
    readonlyLocal () {
      return this.$can('menu.ordenes.publicado')
      // return this.readonly || this.$can('menu.ordenes.publicado')
    },
    min () {
      if (!this.minToday) return null
      else return this.$moment().format('YYYY-MM-DD')
    },
  },

  watch: {
    readonly (val) {
      this.menu = false
    }
  },

  methods: {
    ...mapActions({
      onSave: 'Ordenes/onUpdate',
    }),
    ...mapMutations({
      setId: 'Ordenes/setId',
      setAdvertencia: 'Ordenes/setAdvertencia',
      setObsEstado: 'Ordenes/setObsEstado',
      setObsChofer: 'Ordenes/setObsChofer',
      setEstadoId: 'Ordenes/setEstadoId',
      setEdited: 'Ordenes/setEdited',
      setChoferId: 'Ordenes/setChoferId',
      setDomicilioId: 'Ordenes/setDomicilioId',
      setPublishedAt: 'Ordenes/setPublishedAt'
    }),
    onSelect (value) {
      this.setId(value.id)
      this.setAdvertencia(value.advertencia)
      this.setObsEstado(value.obs_estado)
      this.setObsChofer(value.obs_chofer)
      this.setEstadoId(value.estado_id)
      this.setChoferId(value.chofer_id)
      this.setDomicilioId(value.domicilio_id)
      this.setPublishedAt(value.published_at)
      this.cliente = value.domicilio.cliente
      this.domicilio = value.domicilio
    },
    onSubmit () {
      this.onSave()
        .then(response => {
          this.dialog = false
          this.$emit('refresh', true)
        })
    }
  },
}
</script>
