const URL = '/choferes';

const state = {
  all: [],
  found: [],
  selected: {},
  filters: {
    searchText: null,
  },
  edited: {},
  loading: false,
  headers: [
    {
      text: 'Apellido',
      value: 'apellido',
      align: 'center'
    },
    {
      text: 'Nombre',
      value: 'nombre',
      align: 'center'
    },
    {
      text: 'Usuario',
      value: 'user.email',
      align: 'center'
    },
    {
      text: 'Teléfono',
      value: 'telefono',
      align: 'center'
    },
    // {
    //   text: 'Zona',
    //   value: 'zona.nombre',
    //   align: 'center'
    // },
    {
      text: 'Activo',
      value: 'activo',
      align: 'center'
    },
  ],
  validations: {
    apellido: [
      v => !!v || 'Este campo es necesario',
    ],
    nombre: [
      v => !!v || 'Este campo es necesario',
    ],
    activo: [
      v => !!v || 'Este campo es necesario',
    ],
    telefono: [
      // v => !!v || 'Este campo es necesario',
    ],
    user_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    zona_id: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  defaults: {
    id: null,
    apellido: null,
    activo: true,
    nombre: null,
    telefono: null,
    user_id: true,
    zona_id: true,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  found (state) {
    return state.found
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            searchText: state.filters.searchText,
            sortBy: state.options.sortBy[0],
            sortDesc: state.options.sortDesc[0] ? 1 : 0,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSelectable ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL)
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFound ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL)
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onLoad ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onPredeterminado ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/busqueda/' + payload)
        .then(response => {
          commit('setFound', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDefaults ({ state, commit }) {
    commit('setEdited', state.defaults)
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se registro la informacion del chofer nuevo', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se registro la informacion del chofer nuevo', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se actualizo la informacion del chofer', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se actualizo la informacion del chofer', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se elimino la informacion del chofer', { root: true })
          commit('setEdited', state.defaults)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se elimino la informacion del chofer', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setId (state, payload) {
    state.edited.id = payload
  },
  setApellido (state, payload) {
    state.edited.apellido = payload
  },
  setNombre (state, payload) {
    state.edited.nombre = payload
  },
  setTelefono (state, payload) {
    state.edited.telefono = payload
  },
  setActivo (state, payload) {
    state.edited.activo = payload
  },
  setUserId (state, payload) {
    state.edited.user_id = payload
  },
  setZonaId (state, payload) {
    state.edited.zona_id = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFilterSearchText (state, payload) {
    state.filters.searchText = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
