<template>
  <div>
    <v-dialog
      v-model="drawer"
      width="500"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="drawer = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Menu</v-toolbar-title>
        </v-toolbar>
        <v-list
          nav
          dense
        >
          <v-list-item
            link
          >
            <v-list-item-avatar>
              <v-img :src="'https://ui-avatars.com/api/?bold=true&background=random&name=' + username"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-store-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-wrap">{{ sucursal }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{ name: 'sesion-permisos' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-key</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Permisos</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list
          nav
          dense
        >
          <v-list-item
            v-if="$can('menu.ordenes')"
            :to="{ name: 'ordenes-datatable' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-table</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Ordenes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="$can('menu.clientes')"
            :to="{ name: 'clientes-datatable' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Clientes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item
            v-if="$can('menu.choferes')"
            :to="{ name: 'choferes-list' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-truck</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Choferes</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-list-item
            v-if="$can('menu.zonas')"
            :to="{ name: 'zonas-list' }"
          >
            <v-list-item-icon>
              <v-icon>mdi-terrain</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Zonas</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icon>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-icon>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-theme-light-dark</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Tema oscuro
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-switch
                v-model="$vuetify.theme.dark"
              ></v-switch>
            </v-list-item-action>
          </v-list-item>
          <v-list-item
            @click="onSubmitLogout"
          >
            <v-list-item-icon>
              <v-icon>mdi-power</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                Salir
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import PermisosRefresh from '@/components/PermisosRefresh'
export default {
  components: {
    'my-permiso-refresh': PermisosRefresh,
  },
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      //
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Auth', ['name', 'email']),
    ...mapGetters('Sucursales', {
      selectedSucursal: 'selected'
    }),
    username () {
      return this.name ? this.name.split(' ')[0] : 'Ninguno'
    },
    drawer: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    sucursal () {
      return this.selectedSucursal && this.selectedSucursal.abreviatura && this.selectedSucursal.nombre ? this.selectedSucursal.abreviatura + ' - ' + this.selectedSucursal.nombre : ''
    },
  },
  methods: {
    ...mapActions('Auth', ['onLogout']),
    onSubmitLogout () {
      this.onLogout()
        .then(
          this.$router.push({ name: 'login' })
        )
    },
  }
}
</script>