<template>
  <div>
    <v-container>
      <v-toolbar flat>
        <v-toolbar-title>Permisos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click.stop="onFetch"
        >
          Refrescar
        </v-btn>
      </v-toolbar>
      <v-list>
        <v-list-item
          v-for="(item, index) in collection"
          :key="index"
        >
          <v-list-item-content>
            <!-- <v-list-item-title v-html="item.name"></v-list-item-title> -->
            <!-- <v-list-item-subtitle v-html="item.description"></v-list-item-subtitle> -->
            <v-list-item-title class="text-wrap" v-html="item"></v-list-item-title>
            <v-list-item-subtitle class="text-wrap">Roles can still be used to group permissions for easy assignment, and you can still use the role-based helper methods if truly necessary. But most app-related logic can usually be best controlled using the can methods, which allows Laravel's Gate layer to do all the heavy lifting.</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters({
      collection: 'Auth/permisos',
    }),
  },
  methods: {
    ...mapActions({
      onFetch: 'Auth/onPermisos',
    }),
  }
}
</script>