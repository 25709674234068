<template>
  <div>
    <!-- <v-container grid-list-xs grid-list-sm grid-list-md grid-list-lg grid-list-xl>
      <v-card>
        <v-card-text>
          <p class="text-xs-center display-4 font-weight-black font-italic delete--text">{{ title }}</p>
          <p class="text-xs-center title">{{ text }}</p>
        </v-card-text>
      </v-card>
    </v-container> -->
    <!-- <v-container grid-list-xs grid-list-sm grid-list-md grid-list-lg grid-list-xl> -->
      <v-parallax
        dark
        src="https://htmlcolorcodes.com/assets/images/html-color-codes-color-tutorials-hero-00e10b1f.jpg"
      >
        <v-layout
            align-center
            column
            justify-center
        >
          <p class="text-xs-center display-4 font-weight-black font-italic">{{ title }}</p>
          <p class="text-xs-center title">{{ text }}</p>

          <!-- <h1 class="display-2 font-weight-thin mb-3">{{ title }}</h1>
          <h4 class="subheading">{{ text }}</h4> -->
        </v-layout>
      </v-parallax>
    <!-- </v-container> -->

  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: [
        {
          title: '404',
          text: 'La pagina no pudo ser encontrada'
        },
        {
          title: '401',
          text: 'Usted no tiene autorización para realizar esta operación'
        },
        {
          title: '403',
          text: 'Usted no tiene permiso para realizar esta operación'
        },
        {
          title: '500',
          text: 'Ocurrió un error de conexión con el servidor'
        },
      ]
    }
  },
  computed: {
    title: function () {
      if (this.$route.params.error === undefined) return this.errors[0].title

      let err = this.errors.find((error) => {
        return error.title === this.$route.params.error
      })

      if (err) return err.title
      else return this.errors[0].title
    },

    text: function () {
      if (this.$route.params.error === undefined) return this.errors[0].text

      let err = this.errors.find((error) => {
        return error.title === this.$route.params.error
      })

      if (err) return err.text
      else return this.errors[0].text
    },

  }
}
</script>
