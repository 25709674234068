const URL = '/ordenes';

const state = {
  all: [],
  selected: [],
  chofer_id_nuevo: null,
  filters: {
    published_at: null,
    cliente: null,
    domicilio: null,
    estado_id: 1,
    chofer_id: null,
    sinAsignar: null,
    zona_id: null,
    created: {
      from: null,
      to: null,
    },
    published: {
      from: null,
      to: null,
    },
  },
  loading: false,
  headers: [
    {
      text: 'Cliente',
      value: 'domicilio.cliente',
      align: 'center'
    },
    {
      text: 'Domicilio',
      value: 'domicilio.descripcion',
      align: 'center'
    },
    {
      text: 'Zona',
      value: 'domicilio.zona.nombre',
      align: 'center'
    },
    {
      text: 'Advertencia',
      value: 'advertencia',
      align: 'center'
    }
  ],
  validations: {
    chofer_id_nuevo: [
      v => !!v || 'Este campo es necesario',
    ],
  },
  defaults: {
    id: null,
    chofer_id: null,
    cliente: null,
    domicilio: null,
    domicilio_id: null,
    estado_id: null,
    advertencia: null,
    published_at: null,
    zona_id: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  filters (state) {
    return state.filters
  },
  choferIdNuevo (state) {
    return state.chofer_id_nuevo;
  },
  sort (state) {
    return state.sort
  },
  sortList (state) {
    return state.sort.list
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            cliente: null,//state.filters.cliente,
            domicilio: null,//state.filters.domicilio,
            chofer_id: state.filters.chofer_id,
            created_from: null,//state.filters.created.from,
            created_to: null,//state.filters.created.to,
            estado_id: state.filters.estado_id,
            published_from: state.filters.published_at,
            published_to: state.filters.published_at,
            sinAsignar: state.filters.sinAsignar,
            zona_id: null,//state.filters.zona_id,
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onAddSelected (state, payload) {
    if(payload) state.selected.push(payload)
  },
  onClearSelected (state) {
    state.selected = [];
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/reasignar', {
          chofer_id_nuevo: state.chofer_id_nuevo,
          ordenes: state.selected
        })
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se han guardado las ordenes de distribución', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar las ordenes de distribución', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setSelected (state, payload) {
    state.selected = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    if (state.all) {
      // state.all.push(Object.assign({}, payload))
    }
    state.edited = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFiltersEstadoId (state, payload) {
    state.filters.estado_id = payload
  },
  setFiltersZonaId (state, payload) {
    window.sessionStorage.setItem('ordenes.zona_id', payload)
    state.filters.zona_id = payload
  },
  setFiltersChoferId (state, payload) {
    state.filters.chofer_id = payload
  },
  setChoferIdNuevo (state, payload) {
    state.chofer_id_nuevo = payload
  },
  setFiltersCreatedFrom (state, payload) {
    window.sessionStorage.setItem('ordenes.created.to', payload)
    state.filters.created.from = payload
  },
  setFiltersCreatedTo (state, payload) {
    window.sessionStorage.setItem('ordenes.created.from', payload)
    state.filters.created.to = payload
  },
  setFiltersPublishedAt (state, payload) {
    state.filters.published_at = payload
  },
  setFiltersPublishedFrom (state, payload) {
    window.sessionStorage.setItem('ordenes.published.from', payload)
    state.filters.published.from = payload
  },
  setFiltersPublishedTo (state, payload) {
    window.sessionStorage.setItem('ordenes.published.to', payload)
    state.filters.published.to = payload
  },
  setFiltersSinAsignar (state, payload) {
    window.sessionStorage.setItem('ordenes.sin_asignar', payload)
    state.filters.sinAsignar = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
