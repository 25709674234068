const URL = '/clientes';

const state = {
  all: [],
  found: [],
  display: {
    filters: false,
    ordering: false,
    export: false,
  },
  edited: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: true,
  },
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: true,
  },
  filters: {
    searchText: null,
    cliente_id: null,
    inactivo: null,
  },
  loading: false,
  headers: [
    {
      text: 'Numero',
      value: 'documento',
      align: 'center'
    },
    {
      text: 'Razon social',
      value: 'razon_social',
      align: 'center'
    },
    {
      text: 'Zona',
      value: 'domicilio.zona.nombre',
      align: 'center'
    },
    {
      text: 'Domicilio',
      value: 'domicilio.descripcion',
      align: 'center'
    },
    {
      text: 'Inactivo',
      value: 'inactivo',
      align: 'center'
    },
  ],
  validations: {
    // documento: [
    //   v => !!v || 'Este campo es necesario',
    // ],
    razon_social: [
      v => !!v || 'Este campo es necesario',
    ],
    inactivo: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  default: {
    id: null,
    documento: null,
    razon_social: null,
    inactivo: true,
  },
  relaciones: {
    ordenes: {
      pendientes: 0,
      finalizadas: 0,
      postergadas: 0,
      canceladas: 0
    },
    tanques: 0,
    domicilios: 0
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  found (state) {
    return state.found
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  filters (state) {
    return state.filters
  },
  filtersSelected (state) {
    return state.filters.searchText || state.filters.cliente_id || state.filters.inactivo || false
  },
  relaciones (state) {
    return state.relaciones
  },
  editedAndSelectedEquals (state) {
    return state.edited.id == state.selected.id &&
            state.edited.documento == state.selected.documento &&
            state.edited.razon_social == state.selected.razon_social &&
            state.edited.inactivo == state.selected.inactivo
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  default (state) {
    return state.default
  },
  loading (state) {
    return state.loading
  },
  display (state) {
    return state.display
  }
}

// ACTIONS
const actions = {
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ commit }) {
    commit('setEdited', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          searchText: state.filters.searchText,
          inactivo: state.filters.inactivo,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + state.filters.cliente_id)
        .then(response => {
          const aux = {
            ...state.edited,
            ...response.data
            // id: response.data.id,
            // documento: response.data.documento,
            // razon_social: response.data.razon_social,
            // inactivo: response.data.inactivo
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onLoad ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onPredeterminado ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/busqueda/' + state.filters.searchText)
        .then(response => {
          commit('setFound', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          commit('setEdited', {
            ...state.edited,
            id: response.data.id
          })
          commit('ResponseHTTP/addSuccess', 'Se registro la informacion del cliente nuevo', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se registro la informacion del cliente nuevo', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se actualizo la informacion del cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response.data && error.response.data.data.duplicado) {
            commit('ResponseHTTP/addError', 'Ya existe un registro con el mismo Apellid y nombre', { root: true })
          }
          else {
            commit('ResponseHTTP/addError', 'Error: no se actualizo la informacion del cliente', { root: true })
          }
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onBeforeDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/beforeDestroy/' + state.edited.id)
        .then(response => {
          commit('setRelaciones', {
            ...state.relaciones,
            ordenes: {
              pendientes: response.data.data.ordenes.pendientes,
              finalizadas: response.data.data.ordenes.finalizadas,
              postergadas: response.data.data.ordenes.postergadas,
              canceladas: response.data.data.ordenes.canceladas
            },
            tanques: response.data.data.tanques,
            domicilios: response.data.data.domicilios,
          })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se pudo consultar las advertencias previas a eliminar cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se elimino la informacion del cliente', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se elimino la informacion del cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setRelaciones (state, payload) {
    state.relaciones = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFiltersInactivo (state, payload) {
    state.filters.inactivo = payload
  },
  setDisplay (state, payload) {
    state.display = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
