const URL = '/domicilios';

const state = {
  all: [],
  found: [],
  edited: {
    id: null,
    descripcion: null,
    posicion: null,
    latitud: null,
    longitud: null,
    observaciones: null,
    cliente_id: null,
    zona_id: null,
  },
  selected: {
    id: null,
    descripcion: null,
    posicion: null,
    latitud: null,
    longitud: null,
    observaciones: null,
    cliente_id: null,
    zona_id: null,
  },
  search: '',
  cliente: null,
  filters: {
    searchText: null,
    cliente_id: null,
    domicilio_id: null,
    comienzo: null,
    finalizacion: null,
    periodo: null
  },
  loading: false,
  headers: [
    {
      text: 'Descripción',
      value: 'descripcion',
      align: 'center'
    },
    {
      text: 'Observaciones',
      value: 'observaciones',
      align: 'center'
    },
    {
      text: 'Zona',
      value: 'zona.nombre',
      align: 'center'
    },
    {
      text: 'Geolocalizacion',
      value: 'latitud',
      align: 'center'
    },
    {
      text: 'Posición',
      value: 'posicion',
      align: 'center'
    },
  ],
  headersSelect: [
    {
      text: 'Cliente',
      value: 'cliente.razon_social',
      align: 'center'
    },
    {
      text: 'Descripción',
      value: 'descripcion',
      align: 'center'
    },
    {
      text: 'Observaciones',
      value: 'observaciones',
      align: 'center'
    },
    {
      text: 'Zona',
      value: 'zona.nombre',
      align: 'center'
    },
  ],
  validations: {
    descripcion: [
      v => !!v || 'Este campo es necesario',
    ],
    posicion: [
      // v => !!v || 'Este campo es necesario',
    ],
    latitud: [
      // v => !!v || 'Este campo es necesario',
    ],
    longitud: [
      // v => !!v || 'Este campo es necesario',
    ],
    observaciones: [
      // v => !!v || 'Este campo es necesario',
    ],
    cliente_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) && v > 0 || 'No ha seleccionado un cliente',
    ],
    zona_id: [
      v => !!v || 'Este campo es necesario',
      v => _.isInteger(v) && v > 0 || 'No ha seleccionado un zona',
    ],
  },
  default: {
    id: null,
    descripcion: null,
    observaciones: null,
    latitud: null,
    longitud: null,
    zona_id: null,
    posicion: null,
    cliente_id: null,
    created_at: null,
    updated_at: null,
  },
  relaciones: {
    ordenes: {
      pendientes: 0,
      finalizadas: 0,
      postergadas: 0,
      canceladas: 0
    },
    tanques: 0
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected.descripcion ? state.selected : null
  },
  filters (state) {
    return state.filters
  },
  relaciones (state) {
    return state.relaciones
  },
  updatable (state) {
    return state.edited.id == state.selected.id && (
            state.edited.descripcion != state.selected.descripcion ||
            state.edited.observaciones != state.selected.observaciones ||
            state.edited.cliente_id != state.selected.cliente_id ||
            state.edited.latitud != state.selected.latitud ||
            state.edited.longitud != state.selected.longitud ||
            state.edited.zona_id != state.selected.zona_id ||
            state.edited.posicion != state.selected.posicion
          )
  },
  editedAndSelectedEquals (state) {
    return state.edited.id == state.selected.id &&
            state.edited.descripcion == state.selected.descripcion &&
            state.edited.observaciones == state.selected.observaciones &&
            state.edited.cliente_id == state.selected.cliente_id &&
            state.edited.latitud == state.selected.latitud &&
            state.edited.longitud == state.selected.longitud &&
            state.edited.zona_id == state.selected.zona_id &&
            state.edited.posicion == state.selected.posicion
  },
  itemsLength (state) {
    return state.itemsLength
  },
  search (state) {
    return state.search
  },
  options (state) {
    return state.options
  },
  found (state) {
    return state.found
  },
  headers (state) {
    return state.headers
  },
  headersSelect (state) {
    return state.headersSelect
  },
  rules (state) {
    return state.validations
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearSelected ({ state, commit }) {
    commit('setSelected', state.default)
  },
  onClearEdited ({ state, commit }) {
    commit('setEdited', state.default)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            searchText: state.filters.searchText,
            cliente_id: state.filters.cliente_id
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFetchCliente ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            cliente_id: state.cliente,
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            searchText: state.filters.searchText,
            searchList: state.filters.searchList,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/busqueda', {
          search: state.filters.searchText,
          cliente_id: state.filters.cliente_id
        })
        .then(response => {
          commit('setFound', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + state.filters.cliente_id)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onPredeterminado ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/cliente/' + state.cliente + '/predeterminado')
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          const aux = {
            ...state.edited,
            id: response.data.id
          }
          commit('setEdited', aux)
          commit('setSelected', aux)
          commit('ResponseHTTP/addSuccess', 'Se registro la informacion del domicilio', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response.data && error.response.data.data.duplicado) {
            commit('ResponseHTTP/addError', 'Ya existe un registro con la misma descripción y zona', { root: true })
          }
          else {
            commit('ResponseHTTP/addError', 'Error: no se registro la informacion del domicilio', { root: true })
          }
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      // state.edited.importe = parseFloat(state.edited.importe)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setSelected', state.edited)
          commit('ResponseHTTP/addSuccess', 'Se actualizo la informacion del domicilio', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          if (error.response.data && error.response.data.data.duplicado) {
            commit('ResponseHTTP/addError', 'Ya existe un registro con la misma descripción y zona', { root: true })
          }
          else {
            commit('ResponseHTTP/addError', 'Error: no se actualizo la informacion del domicilio', { root: true })
          }
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onBeforeDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/beforeDestroy/' + state.edited.id)
        .then(response => {
          commit('setRelaciones', {
            ...state.relaciones,
            ordenes: {
              pendientes: response.data.data.ordenes.pendientes,
              finalizadas: response.data.data.ordenes.finalizadas,
              postergadas: response.data.data.ordenes.postergadas,
              canceladas: response.data.data.ordenes.canceladas
            },
            tanques: response.data.data.tanques
          })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se pudo consultar las advertencias previas a eliminar domicilio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se elimino la informacion del domicilio', { root: true })
          commit('setEdited', state.default)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error: no se elimino la informacion del domicilio', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setRelaciones (state, payload) {
    state.relaciones = Object.assign({}, payload)
  },
  setFilters (state, payload) {
    state.filters = Object.assign({}, payload)
  },
  setFound (state, payload) {
    state.found = payload.slice()
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFilterSearchList (state, payload) {
    state.filters.searchList = payload.slice()
  },
  setSearch (state, payload) {
    state.search = payload
  },
  setCliente (state, payload) {
    state.cliente = payload
  },
  setFiltersClienteId (state, payload) {
    state.filters.cliente_id = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
