<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-alert
            border="left"
            colored-border
            color="warning"
            icon="mdi-map-marker-plus"
            elevation="2"
          >
            Este formulario se utiliza para registrar un Domicilio Nuevo
            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
            >
              <v-icon left>mdi-keyboard-f4</v-icon>
              Registrar
              <v-icon right>mdi-check</v-icon>
            </v-btn>
          </v-alert>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="descripcion"
                placeholder="Descripcion"
                prepend-icon="mdi-text"
                :rules="rules.descripcion"
                label="Descripción"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="observaciones"
                placeholder="Observaciones"
                prepend-icon="mdi-text"
                :rules="rules.observaciones"
                label="Observaciones del domicilio"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <my-zona-select
                v-model="zona"
                :rules="rules.zona_id"
              ></my-zona-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="posicion"
                prepend-icon="mdi-text"
                :rules="rules.posicion"
                label="Posición"
                type="number"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-zona-select': ZonaSelect
  },
  props: {
    reset: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
      redirect: 'domicilios-list',
      attrs: {
        color: 'primary',
        readonly: false,
      },
    }
  },
  created () {
    this.onClearEdited()
    if (this.$route.params.id)
      this.cliente = this.$route.params.id
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
  },
  computed: {
    ...mapGetters({
      edited: 'Domicilios/edited',
      rules: 'Domicilios/rules',
      loading: 'Domicilios/loading',
    }),
    id () {
      return this.edited.id
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    posicion: {
      get () {
        return this.edited.posicion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          posicion: value
        })
      }
    },
    latitud: {
      get () {
        return this.edited.latitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          latitud: value
        })
      }
    },
    longitud: {
      get () {
        return this.edited.longitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          longitud: value
        })
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          observaciones: value
        })
      }
    },
    cliente: {
      get () {
        return this.edited.cliente_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          zona_id: value
        })
      }
    },
  },
  watch: {
    reset (value) {
      if (value) this.$ref.form.reset()
    }
  },
  methods: {
    ...mapActions('Domicilios', ['onSave', 'onClearEdited', 'onFetch']),
    ...mapMutations('Domicilios', ['setEdited', 'setSelected']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.onFetch()
        })
      }
    },
    handleKeyboard(event) {
      if (!this.loading) {
        if (event.key === 'F4') {
          this.onSubmit(event)
        }
      }
    },
  },
}
</script>
