import Vue from 'vue'
import Vuex from 'vuex'

import Auth from '@/store/modules/Auth'
import Choferes from '@/store/modules/Choferes'
import Clientes from '@/store/modules/Clientes'
import ClientesFindOrCreate from '@/store/modules/ClientesFindOrCreate'
import Domicilios from '@/store/modules/Domicilios'
import DomiciliosFindOrCreate from '@/store/modules/DomiciliosFindOrCreate'
import Envases from '@/store/modules/Envases'
import Estadisticas from '@/store/modules/Estadisticas'
import Estados from '@/store/modules/Estados'
import OrdenesReasignar from '@/store/modules/OrdenesReasignar'
import Ordenes from '@/store/modules/Ordenes'
import OrdenesPendientes from '@/store/modules/OrdenesPendientes'
import OrdenesHist from '@/store/modules/OrdenesHist'
import Movimientos from '@/store/modules/Movimientos'
import Sucursales from '@/store/modules/Sucursales'
import Zonas from '@/store/modules/Zonas'
import ResponseHTTP from '@/store/modules/ResponseHTTP'


// import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    Auth,
    Choferes,
    Clientes,
    ClientesFindOrCreate,
    Domicilios,
    DomiciliosFindOrCreate,
    Estadisticas,
    Envases,
    Estados,
    Ordenes,
    OrdenesPendientes,
    OrdenesReasignar,
    OrdenesHist,
    Movimientos,
    Sucursales,
    Zonas,
    ResponseHTTP,
  },
  // strict: debug,
  // plugins: debug ? [createLogger()] : []
})