import Vue from 'vue'
import { mapGetters } from 'vuex'

const mixin = {
    methods: {
        $can(permissionName) {
            let permissions = JSON.parse(window.sessionStorage.getItem('permissions') || '[]')
            return permissions.indexOf(permissionName) !== -1
        }
    }
}

Vue.mixin(mixin)