<template>
  <div>
    <v-sheet>
      <v-container>
        <div
          class="text-center display-4"
        >
          401
        </div>
        <div
          class="text-center headline"
        >
          Sin autenticación
        </div>
        <div
          class="text-center body-1"
        >
          Usted debe iniciar sesión para visualizar esta pantalla. 
        </div>
      </v-container>
      <v-container>
        <v-btn
          text
          block
          color="primary"
          @click.prevent="onLogin"
        >
          <v-icon left>mdi-login</v-icon>
          <div>Iniciar sesión</div>
        </v-btn>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  computed: {
    //
  },
  watch: {
    //
  },
  methods: {
    onLogin () {
      this.$router.push({ name: 'login' })
    },
  },

}
</script>
