 <template>
  <div>
    <div id="map"></div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
export default {
  name: 'MyMapa',
  data () {
    return {
      mapInstance: null,
    }
  },
  mounted () {
    this.onDibujarMapa()
  },
  created () {
    //
  },
  computed: {
    ...mapGetters({
      edited: 'Ordenes/edited',
      collection: 'Ordenes/all',
      loading: 'Ordenes/loading',
    }),
  },
  watch: {
    collection () {
      this.onActualizarMapa()
    }
  }, 
  methods: {
    onRemoveMap () {
      // Verifica si el contenedor ya tiene un mapa inicializado
      if (this.mapInstance) {
        // Si ya hay un mapa, destrúyelo antes de inicializar uno nuevo
        this.mapInstance.remove()
      }
    },
    onDibujarMapa () {
      this.onRemoveMap()
      const coordenadas = {
        latitud: -54.79088574483291, 
        longitud: -68.22960536802813
      }
      this.mapInstance = L.map('map').setView([coordenadas.latitud, coordenadas.longitud], 15);
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.mapInstance);
    },
    onActualizarMapa () {
      if (this.collection && this.collection.length >= 0) {
        // Limpiar el mapa antes de agregar nuevos marcadores
        this.mapInstance.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            this.mapInstance.removeLayer(layer);
          }
        });
        const pintables = this.collection.filter(item => {
          console.log('item.domicilio.descripcion')
          return item.domicilio.latitud && item.domicilio.longitud
        })
        // Agregar marcadores para cada elemento en la colección
        pintables.forEach(item => {
          if (item.domicilio && item.domicilio.latitud && item.domicilio.longitud) {
            const title = `${item.domicilio.cliente.razon_social}${item.domicilio.descripcion ? ', ' + item.domicilio.descripcion : ''}${item.domicilio.observaciones ? '. ' + item.domicilio.observaciones : ''}`;
            L.marker([item.domicilio.latitud, item.domicilio.longitud])
              .addTo(this.mapInstance)
              .bindPopup(title)
          }
        })
      }
    },
  }
}
</script>
<style>
  #map {
    width: 100%;
    height: 600px; /* ajusta la altura según tus necesidades */
  }
</style>