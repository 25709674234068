<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-toolbar flat>
            <v-btn
              text
              @click="onBack"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-chevron-left</v-icon>
              <div class="hidden-xs-only">{{ backButtonText }}</div>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click.stop="onSubmit"
              :loading="loading"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-check</v-icon>
              <div class="hidden-xs-only">{{ submitButtonText }}</div>
            </v-btn>
          </v-toolbar>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="apellido"
                placeholder="Ingrese el apellido"
                label="Apellido"
                :rules="rules.apellido"
                prepend-icon="mdi-label"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                placeholder="Ingrese un nombre"
                :rules="rules.nombre"
                prepend-icon="mdi-label"
                label="Nombre"
                color="primary"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="telefono"
                placeholder="Ingrese un número de teléfono"
                :rules="rules.telefono"
                prepend-icon="mdi-phone"
                label="Teléfono"
                color="primary"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="advertencia"
                placeholder="Ingrese un advertencia"
                prepend-icon="mdi-map-marker"
                label="Observaciones"
                :rules="rules.advertencia"
                color="primary"
                clearable
              ></v-text-field>
            </v-col> -->
            <!-- <v-col cols="12">
              <my-zona-select
                v-model="zona"
                label="ZonaId"
              ></my-zona-select>
            </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    // 'my-zona-select': ZonaSelect
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
    }
  },
  created () {
    this.onDefaults()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      edited: 'Choferes/edited',
      rules: 'Choferes/rules',
      loading: 'Choferes/loading',
    }),
    apellido: {
      get () {
        return this.edited.apellido
      },
      set (value) {
        this.setApellido(value)
      }
    },
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setNombre(value)
      }
    },
    telefono: {
      get () {
        return this.edited.telefono
      },
      set (value) {
        this.setTelefono(value)
      }
    },
    activo: {
      get () {
        return this.edited.activo
      },
      set (value) {
        this.setActivo(value)
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setZonaId(value)
      }
    },
  },
  methods: {
    ...mapActions({
      onSave: 'Choferes/onUpdate',
      onFind: 'Choferes/onFind',
      onDefaults: 'Choferes/onDefaults',
    }),
    ...mapMutations({
      setApellido: 'Choferes/setApellido',
      setNombre: 'Choferes/setNombre',
      setTelefono: 'Choferes/setTelefono',
      setActivo: 'Choferes/setActivo',
      setZonaId: 'Choferes/setZonaId',
    }),
    onBack () {
      this.$router.go(-1)
    },
    onSubmit () {
      this.onSave().then(response => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
