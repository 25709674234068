<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :search="search"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      @click:row="onSelect"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-subheader>Domicilios asociados al cliente</v-subheader>
        </v-toolbar>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ searchText }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de domicilios para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      search: '',
      groupBy: 'dia'
    }
  },
  created () {
    if (this.$route.params.id) {
      this.setFilters({
        ...this.filters,
        cliente_id: this.$route.params.id
      })
    }
  },
  computed: {
    ...mapGetters({
      all: 'Domicilios/all',
      headers: 'Domicilios/headers',
      filters: 'Domicilios/filters',
      loading: 'Domicilios/loading',
      itemsLength: 'Domicilios/itemsLength',
      options: 'Domicilios/options',
    }),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('Domicilios', ['onFetch']),
    ...mapMutations('Domicilios', ['setOptions', 'setFiltersClienteId', 'setEdited', 'setSelected', 'setFilters']),
     onSelect (value) {
      const selected = {
        id: value.id,
        descripcion: value.descripcion,
        observaciones: value.observaciones,
        latitud: value.latitud,
        longitud: value.longitud,
        cliente_id: value.cliente_id,
        posicion: value.posicion,
        zona_id: value.zona_id,
      }
      this.setEdited(selected)
      this.setSelected(selected)
    },
  }
}
</script>
