<template>
  <div>
    <v-sheet>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          dark
          color="error"
          @click="onSubmit"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-delete</v-icon>
          <div class="hidden-xs-only">{{ submitButtonText }}</div>
        </v-btn>
      </v-toolbar>
      <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :value="edited.apellido"
                label="Apellido"
                prepend-icon="mdi-label"
                color="error"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                :value="edited.nombre"
                prepend-icon="mdi-label"
                label="Nombre"
                color="error"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="edited.telefono"
                prepend-icon="mdi-phone"
                label="Teléfono"
                color="error"
                readonly
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="advertencia"
                placeholder="Ingrese un advertencia"
                prepend-icon="mdi-map-marker"
                label="Observaciones"
                :rules="rules.advertencia"
                color="primary"
                clearable
              ></v-text-field>
            </v-col> -->
          <!-- <v-col cols="12">
            <v-text-field
              :value="edited.zona_id"
              label="Zona"
              color="error"
              prepend-icon="mdi-counter"
              readonly
            ></v-text-field>
          </v-col> -->
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
// import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    // 'my-zona-select': ZonaSelect
  },
  data () {
    return {
      redirect: 'choferes-list',
      title: '¿Está seguro de borrar este registro?',
      submitButtonText: 'Borrar',
      backButtonText: 'Volver',
    }
  },
  created () {
    this.onDefaults()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      edited: 'Choferes/edited',
      loading: 'Choferes/loading',
    }),
  },
  methods: {
    ...mapActions({
      onDelete: 'Choferes/onDelete',
      onFind: 'Choferes/onFind',
      onDefaults: 'Choferes/onDefaults',
    }),
    onCancel () {
      this.$router.go(-1)
    },
    onSubmit () {
      this.onDelete().then(response => {
        this.$router.go(-1)
      })
    },
  },
}
</script>
