<template>
  <div>
    <v-sheet
      v-show="showFilters"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showFilters = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onClearFilters"
          :disabled="!filtersSelected"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="searchText"
              label="Escriba algo..."
              hint="Enter para buscar"
              clearable
              @keydown.enter="onActualizar"
              @click:clear="onClearFilters"
            >
              <template v-slot:prepend>
                <v-btn
                  text
                  color="primary"
                  @click="onActualizar"
                  hint="Enter para buscar cliente"
                  :disabled="!searchText || searchText.length == 0"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          <!--
            <v-text-field
              v-model="cliente"
              label="Cliente"
              hint="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onActualizar"
              @click:clear="onClearFilters"
            ></v-text-field>
            -->
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!--
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showOrdering = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-chip-group
              v-model="chipSelected"
              column
              active-class="primary"
            >
              <v-chip @click.stop="onOrdenamientoPorDefecto(0)">Por defecto</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicadosAntiguosPendientes(1)">Publicados antiguos y pendientes</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicadosRecientesPendientes(2)">Publicados recientes y pendientes</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizacionesRecientes(3)">Actualizaciones recientes</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicados(1, 4)">Publicados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicados(0, 5)">Publicados antiguos</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      title: 'Listado de clientes',
      search: '',
      selected: [],
    }
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Clientes', ['display', 'loading', 'filters', 'filtersSelected']),
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value
        })
      }
    },
    showFilters: {
      get () {
        return this.display.filters
      },
      set (value) {
        this.setDisplay({
          ...this.display,
          filters: value
        })
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', ['setFilters', 'setDisplay']),
    onClearFilters (event) {
      event.preventDefault()
      this.searchText = null
      // this.setFilters({
      //   ...this.filters,
      //   searchText: null
      // })
      this.onFetch()
    },
    onActualizar (event) {
      event.preventDefault()
      this.onFetch()
    },
  }
}
</script>
