<template>
  <v-tabs>
    <v-tab
      grow
      @click="selectedLocal = null"
    >
      <v-icon left>mdi-list-status</v-icon>
      Todos
    </v-tab>
    <v-tab
      grow
      v-for="(item, index) in collection"
      :key="index"
      @click="selectedLocal = item.id"
      dark
    >
      <v-icon
        v-if="item.icono"
        v-html="item.icono"
        left
      >
        <!-- :style="'color:' + item.color" -->
      </v-icon>
      {{ item.nombre }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      placeholder: 'Seleccione un estado',
      label: 'Estado'
    }
  },
  created () {
    this.onFetch()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters({
      collection: 'Estados/all'
    })
  },

  watch: {
    selectedLocal (value) {
      //
    }
  },
  methods: {
    ...mapActions({
      onFetch: 'Estados/onFetch'
    }),
    onChange (value) {
      // this.selectedLocal = this.collection.value
    }
  }
}
</script>
