<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="withNombre">
          <v-chip
            v-if="orden.chofer"
            v-bind="attrs"
            v-on="on"
            @click.prevent="onSelect(orden)"
          >
            <v-icon
              v-if="!iconOff"
              left
              small
              v-text="'mdi-truck'"
            ></v-icon>
            <div>
              {{ orden.chofer.nombre + ' ' + orden.chofer.apellido }}
            </div>
          </v-chip>
          <v-chip
            v-else
            v-bind="attrs"
            v-on="on"
            @click.prevent="onSelect(orden)"
          >
            <!-- <v-icon
              left
              small
              v-text="'mdi-truck'"
            ></v-icon> -->
            {{ empty }}
          </v-chip>
        </div>
        <div v-else>
          <v-avatar
            v-if="orden.chofer"
            v-bind="attrs"
            v-on="on"
            size="30"
          >
            <v-img :src="'https://ui-avatars.com/api/?bold=true&background=random&name=' + orden.chofer.nombre + '%20' + orden.chofer.apellido"></v-img>
          </v-avatar>
          <v-avatar
            v-else
            v-bind="attrs"
            v-on="on"
            size="30"
            color="primary"
          >
          </v-avatar>
        </div>
      </template>

      <v-card>
        <v-toolbar flat>
          <v-btn
            text
            @click="dialog = false"
            :loading="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cambiar chofer</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="onSubmit"
            :loading="loading"
            v-if="$can('menu.ordenes.chofer')"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-check</v-icon>
            <div class="hidden-xs-only">Guardar</div>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-if="cliente"
                :value="cliente.razon_social ? cliente.razon_social : cliente.nombre + ' ' + cliente.apellido"
                label="Cliente"
                prepend-icon="mdi-account"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selected"
                :items="collection"
                hide-no-data
                :item-text="item => item.nombre + ' ' + item.apellido"
                item-value="id"
                :rules="rules"
                prepend-icon="mdi-truck"
                :label="label"
                :placeholder="placeholder"
                color="primary"
                :readonly="!$can('menu.ordenes.chofer')"
                :clearable="clearable"
              ></v-select>
            </v-col>
            <!-- <v-col cols="12">
              <v-text-field
                v-model="advertencia"
                placeholder="Ingrese una observacion"
                label="Observaciones"
                prepend-icon="mdi-text"
                color="primary"
                clearable
                :readonly="!$can('menu.ordenes.chofer')"
              ></v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    orden: {
      type: Object,
      default: null
    },
    rules: Array,
    iconOff: {
      type: Boolean,
      default: false
    },
    withNombre: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    chip: {
      type: Boolean,
      default: false
    },
    descripcion: {
      type: String,
      default: ''
    },
    icono: {
      type: String,
      default: 'mdi-plus'
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      placeholder: 'Seleccione un chofer',
      label: 'Chofer',
      dialog: false,
      cliente: null,
      domicilio: null,
      empty: 'Sin asignar'
    }
  },

  created () {
    //
  },

  mounted () {
    //
  },

  computed: {
    selected: {
      get () {
        if (this.orden) return this.orden.chofer_id
        else null
      },
      set (value) {
        this.setId(this.orden.id)
        this.setAdvertencia(this.orden.advertencia)
        this.setEstadoId(this.orden.estado_id)
        this.setChoferId(value) 
        this.setDomicilioId(this.orden.domicilio_id)
      }
    },
    advertencia: {
      get () {
        return this.orden.advertencia
      },
      set (value) {
        this.setAdvertencia(value)
      }
    },
    ...mapGetters({
      collection: 'Choferes/all',
      edited: 'Ordenes/edited',
      loading: 'Ordenes/loading',
    }),
  },

  methods: {
    ...mapActions({
      onFetch: 'Choferes/onFetch',
      onSave: 'Ordenes/onUpdate',
    }),
    ...mapMutations({
      setId: 'Ordenes/setId',
      setAdvertencia: 'Ordenes/setAdvertencia',
      setObsEstado: 'Ordenes/setObsEstado',
      setObsChofer: 'Ordenes/setObsChofer',
      setEstadoId: 'Ordenes/setEstadoId',
      setEdited: 'Ordenes/setEdited',
      setChoferId: 'Ordenes/setChoferId',
      setDomicilioId: 'Ordenes/setDomicilioId',
      setPublishedAt: 'Ordenes/setPublishedAt',
    }),
    onSelect (value) {
      this.setId(value.id)
      this.setAdvertencia(value.advertencia)
      this.setObsEstado(value.obs_estado)
      this.setObsChofer(value.obs_chofer)
      this.setEstadoId(value.estado_id)
      this.setChoferId(value.chofer_id)
      this.setPublishedAt(value.published_at)
      this.setDomicilioId(value.domicilio_id)
      this.cliente = value.domicilio.cliente
      this.domicilio = value.domicilio
    },
    onSubmit () {
      this.onSave()
        .then(response => {
          this.dialog = false
          this.$emit('refresh', true)
        })
    }
  },
}
</script>
