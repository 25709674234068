<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      disable-sort
      @click:row="onSelect"
    >
      <template v-slot:item.inactivo="{ item }">
        {{ item.inactivo ? 'Si' : '' }}
      </template>
      <template v-slot:item.razon_social="{ item }">
        <div
          v-if="item.razon_social"
        >
          {{ item.razon_social }}
        </div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de clientes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      selected: [],
    }
  },
  created () {
    //
  },
  computed: {
    ...mapGetters('Clientes', ['all', 'headers', 'loading', 'itemsLength', 'options']),
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', ['setOptions', 'setEdited', 'setSelected']),
    onSelect (value) {
      const selected = {
        id: value.id,
        documento: value.documento,
        razon_social: value.razon_social,
        inactivo: value.inactivo,
      }
      this.setEdited(selected)
      this.setSelected(selected)
      this.$router.push({ name: 'clientes-form', params: { id: selected.id } })
    },
  }
}
</script>
