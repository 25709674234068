<template>
  <div v-if="status">
    <v-sheet>
      <v-container>
        <div
          class="text-center display-4"
        >
          403
        </div>
        <div
          class="text-center headline"
        >
          Sin autorización
        </div>
        <div
          class="text-center body-1"
        >
          Usted no tiene permiso para visualizar esta pantalla. 
        </div>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },

  computed: {
    ...mapGetters({
      status: 'Auth/forbidden'
    }),
  },

  watch: {
    //
  },

}
</script>
