<template>
  <div v-if="list">
    <v-list flat>
      <v-list-item-group
        v-model="selectedLocal"
      >
        <template v-for="(item, i) in collectionLocal">
          <v-divider
            v-if="!item"
            :key="`divider-${i}`"
          ></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item.id"
            active-class="blue--text"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="text-wrap" v-text="item.nombre"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="blue accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
    <!-- <v-chip-group
      column
      mandatory
      v-model="selectedLocal"
      active-class="deep-purple--text text--accent-4"
    >
      <v-chip
        :color="selectedLocal + 1  === item.id ? item.color : ''"
        :dark="selectedLocal + 1 === item.id"
        v-for="(item, index) in collectionLocal"
        :key="index"
        :value="item.id"
      >
        <v-icon left>
          {{ item.icono }}
        </v-icon>
        {{ item.nombre }}
      </v-chip>
    </v-chip-group> -->
  </div>
  <div v-else>
    <v-select
      v-model="selectedLocal"
      :items="collectionLocal"
      hide-no-data
      item-text="nombre"
      item-value="id"
      :rules="rules"
      :prepend-icon="!hideIcon ? 'mdi-list-status': ''"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          dense
        >
          <v-list-item-icon>
            <v-icon v-html="item.icono"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
    ocultarEstados: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      placeholder: 'Seleccione un estado',
      label: 'Estado'
    }
  },
  created () {
    this.onFetch()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.$emit('change', value)
      }
    },
    ...mapGetters({
      collection: 'Estados/all'
    }),
    collectionLocal () {
      if (!this.ocultarEstados) return this.collection
      else return this.collection.filter(data => { return data.id in this.ocultarEstados; console.log(data); })
    }
  },

  watch: {
    selectedLocal (value) {
      //
    }
  },
  methods: {
    ...mapActions({
      onFetch: 'Estados/onFetch'
    }),
    onChange (value) {
      // this.selectedLocal = this.collection.value
    }
  }
}
</script>
