import Vue from 'vue'

// const moment = require('moment')
// require('moment/locale/es')
 
// Vue.use(require('vue-moment'), {
//     moment
// })

import VueApexCharts from 'vue-apexcharts'

Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)