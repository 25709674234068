<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :readonly="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <div v-if="chip">
          <v-chip
            outlined
            v-bind="attrs"
            v-on="on"
          >
            {{ label }} {{ selected | moment('dddd, DD/MM') }}
          </v-chip>
        </div>
        <div
          v-else
        > -->
          <v-text-field
            :value="formatted"
            :label="label"
            :placeholder="placeholder"
            :prepend-icon="!hideIcon ? 'mdi-calendar' : ''"
            readonly
            :rules="rules"
            v-bind="attrs"
            v-on="on"
            :clearable="clearable"
            @click:clear="selectedLocal = null"
          ></v-text-field>
        <!-- </div> -->
      </template>
      <v-date-picker
        v-model="selectedLocal"
        header-color="primary"
        locale="es-ar"
        @input="menu = false"
        :readonly="readonly"
        :min="min"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: String,
    label: String,
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    },
    chip: {
      type: Boolean,
      default: false
    },
    attrs: {
      type: Object,
      default: null
    },
    rules: Array,
    clearable: {
      type: Boolean,
      default: false
    },
    minToday: {
      type: Boolean,
      default: false
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      menu: false,
    }
  },

  computed: {
    // formatted () {
    //   return this.selected ? this.$moment(this.selected).format('DD/MM/YYYY') : ''
    // },
    formatted () {
      if (!this.selected) return null
      const [year, month, day] = this.selected.substr(0, 10).split('-')
      return day + '/' + month + '/' + year
      // return this.$moment(this.selected).format('DD/MM/YYYY')
    },
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    min () {
      if (!this.minToday) return null
      else return this.$moment().format('YYYY-MM-DD')
    },
  },

  watch: {
    readonly (val) {
      this.menu = false
    }
  },

}
</script>
