import Lodash from 'lodash'
import Vue from 'vue'
import Axios from 'axios'

const http = Axios;

const successInterceptor = response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}

const errorInterceptor = error => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  // all the other error responses
  switch (error.response.status) {

    case 401:
      // authentication error, logout the user
      console.error(error.response.status, error.message);
      window.sessionStorage.removeItem('token_type')
      window.sessionStorage.removeItem('access_token')
      window.sessionStorage.removeItem('permissions')
      window.location.href = '/unauthorized'
      break;

    default:
      break;
  }

  return Promise.reject(error);
}

// Se agrega las funciones successInterceptor y errorInterceptor para las respuestas HTTP
http.interceptors.response.use(successInterceptor, errorInterceptor);
// Se agrega encabezado inicial para todas las solicitudes HTTP
http.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
// Se agrega la URL base
http.defaults.baseURL = process.env.MIX_API_URL;
// Se agrega la función authInterceptor para las solicitudes HTTP
http.interceptors.request.use(config => {
  config.headers['Authorization'] = window.sessionStorage.getItem('token_type') + ' ' + window.sessionStorage.getItem('access_token')

  // Obtener el valor de selected.id desde Vuex y asignarlo a una constante
  const sucursalId = window.sessionStorage.getItem('sucursal_id')
  // Agregar el encabezado 'sucursal_id' a la solicitud si hay un valor
  if (sucursalId) {
    config.headers['X-Sucursal-Id'] = sucursalId;
  }

  // Devolver el objeto como respuesta
  return config;
});

// Para finalizar se agregan las librerias al objeto window
window._ = Lodash;
window.axios = http;