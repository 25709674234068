<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-form
              @submit.prevent="onSubmit"
            >
              <v-card class="elevation-4">
                <v-toolbar
                  color="primary"
                  dark
                >
                  <v-toolbar-title>Autenticación</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-text-field
                    v-model="model.email"
                    label="Nombre de usuario"
                    name="username"
                    prepend-icon="mdi-account"
                    type="text"
                  ></v-text-field>

                  <v-text-field
                    v-model="model.password"
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    type="submit"
                    :loading="loading"
                  >
                    Entrar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {

    data () {
      return {
        model: {
          email: '',
          password: '',
        },
      }
    },

    created () {
      //
    },

    computed: {
      ...mapGetters({
        errors: 'Auth/errors',
        loading: 'Auth/loading',
      }),
    },

    methods: {
      ...mapActions({
        onLogin: 'Auth/onLogin',
        onMe: 'Auth/onMe'
      }),
      onSubmit () {
        this.onLogin(this.model).then(response => {
          this.onMe()
          this.$router.push({ name: 'ordenes-datatable' })
        });
      },
    },
  }
</script>