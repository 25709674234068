<template>
  <v-app style="background: rgb(38,50,56);
background: -moz-linear-gradient(90deg, rgba(38,50,56,1) 0%, rgba(69,90,100,1) 35%, rgba(38,50,56,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(38,50,56,1) 0%, rgba(69,90,100,1) 35%, rgba(38,50,56,1) 100%);
background: linear-gradient(90deg, rgba(38,50,56,1) 0%, rgba(69,90,100,1) 35%, rgba(38,50,56,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#263238',endColorstr='#263238',GradientType=1);">
      <router-view />
  </v-app>
</template>

<script>

export default {
  name: 'App'
}
</script>