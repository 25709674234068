<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-toolbar flat>
            <v-btn
              text
              @click="onCancel"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              {{ backButtonText }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click.stop="onSubmit"
              :loading="loading"
              v-if="$can('zonas.update')"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-check</v-icon>
              <div class="hidden-xs-only">{{ submitButtonText }}</div>
            </v-btn>
          </v-toolbar>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="orden"
                placeholder="Ingrese un número de orden"
                label="Número de orden"
                :rules="rules.orden"
                prepend-icon="mdi-counter"
                color="primary"
                clearable
                :readonly="!$can('zonas.update')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                placeholder="Ingrese un nombre"
                :rules="rules.nombre"
                prepend-icon="mdi-label"
                label="Nombre"
                color="primary"
                clearable
                :readonly="!$can('zonas.update')"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="observaciones"
                placeholder="Ingrese un observaciones"
                prepend-icon="mdi-map-marker"
                label="Observaciones"
                :rules="rules.observaciones"
                color="primary"
                clearable
                :readonly="!$can('zonas.update')"
              ></v-text-field>
            </v-col>
            <!-- <v-col cols="12">
              <my-zona-select
                v-model="zona"
                label="ZonaId"
              ></my-zona-select>
            </v-col> -->
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
// import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    // 'my-zona-select': ZonaSelect
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
      redirect: 'zonas-list',
    }
  },
  created () {
    this.onDefaults()
    if (this.$route.params.id)
      this.onFind(this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      edited: 'Zonas/edited',
      rules: 'Zonas/rules',
      loading: 'Zonas/loading',
    }),
    orden: {
      get () {
        return this.edited.orden
      },
      set (value) {
        this.setOrden(value)
      }
    },
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setNombre(value)
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setObservaciones(value)
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setZonaId(value)
      }
    },
  },
  methods: {
    ...mapActions({
      onSave: 'Zonas/onUpdate',
      onFind: 'Zonas/onFind',
      onDefaults: 'Zonas/onDefaults',
    }),
    ...mapMutations({
      setOrden: 'Zonas/setOrden',
      setNombre: 'Zonas/setNombre',
      setObservaciones: 'Zonas/setObservaciones',
      setZonaId: 'Zonas/setZonaId',
    }),
    onCancel () {
      this.$router.push({ name: this.redirect })
    },
    onSubmit () {
      this.onSave().then(response => {
        this.$router.push({ name: this.redirect })
      })
    },
  },
}
</script>
