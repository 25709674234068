<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="1024"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          color="primary"
          v-bind="attrs"
          v-on="on"
          @click.prevent="onSelect(movimiento)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-form
        ref="form"
        v-model="valid">
        <v-card>
          <v-toolbar flat>
            <v-btn
              text
              @click="dialog = false"
              :loading="loading"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Editar movimiento</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="onSubmit"
              :loading="loading"
              v-if="$can('menu.ordenes.chofer')"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-check</v-icon>
              <div class="hidden-xs-only">Guardar</div>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4">
                <my-chofer-select
                  v-model="chofer"
                  :rules="rules.chofer_id"
                ></my-chofer-select>
              </v-col>
              <v-col cols="12" md="4">
                <my-envase-select
                  v-model="envase"
                ></my-envase-select>
              </v-col>
              <v-col cols="12" md="4">
                <my-date-picker
                  v-model="salidasFecha"
                  label="Fecha de salida"
                ></my-date-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="salidasCantidadPropios"
                  placeholder="Envases egresados de la planta"
                  label="Salidas"
                  prepend-icon="mdi-counter"
                  color="primary"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="ventasCantidadPropios"
                  placeholder="Envases ingresados de Sartini Gas"
                  label="Ventas"
                  prepend-icon="mdi-counter"
                  color="primary"
                  clearable
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="ventasCantidadCompetencia"
                  placeholder="Envases ingresados de la Competencia"
                  label="En G.A."
                  prepend-icon="mdi-counter"
                  color="primary"
                  clearable
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-btn
              color="error"
              block
              @click.stop="onDeleteButton"
            >
              <v-icon left>mdi-delete</v-icon>
              Eliminar
            </v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>

    <v-sheet>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EnvaseSelect from '@/pages/envases/Select'
import ChoferSelect from '@/pages/choferes/Select'
import DatePicker from '@/components/DatePicker'
export default {
  components: {
    'my-envase-select': EnvaseSelect,
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker,
  },
  props: {
    movimiento: {
      type: Object,
      default: null
    },
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      cancelButtonText: 'Volver',
      dialog: false,
    }
  },
  created () {
    if (!this.edited.chofer_id) this.setChoferId(null)
    if (!this.edited.envase_id) this.setEnvaseId(null)
    this.setId(null)
    this.setSalidasFecha(null)
    this.setSalidasCantidadPropios(null)
    this.setVentasFecha(null)
    this.setVentasCantidadPropios(null)
    this.setVentasCantidadCompetencia(null)
  },
  computed: {
    ...mapGetters({
      edited: 'Movimientos/edited',
      rules: 'Movimientos/rules',
      loading: 'Movimientos/loading',
      itemsLength: 'Movimientos/itemsLength',
    }),

    salidasFecha: {
      get () {
        return this.edited.salidas_fecha
      },

      set (value) {
        this.setSalidasFecha(value)
      }
    },
    salidasCantidadPropios: {
      get () {
        return this.edited.salidas_cantidad_propios
      },

      set (value) {
        this.setSalidasCantidadPropios(value)
      }
    },
    ventasFecha: {
      get () {
        return this.edited.ventas_fecha
      },

      set (value) {
        this.setVentasFecha(value)
      }
    },
    ventasCantidadPropios: {
      get () {
        return this.edited.ventas_cantidad_propios
      },

      set (value) {
        this.setVentasCantidadPropios(value)
      }
    },
    ventasCantidadCompetencia: {
      get () {
        return this.edited.ventas_cantidad_competencia
      },

      set (value) {
        this.setVentasCantidadCompetencia(value)
      }
    },
    chofer: {
      get () {
        return this.edited.chofer_id
      },
      set (value) {
        this.setChoferId(value)
      }
    },
    envase: {
      get () {
        return this.edited.envase_id
      },
      set (value) {
        this.setEnvaseId(value)
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      onSave: 'Movimientos/onUpdate',
      onDelete: 'Movimientos/onDelete',
      onDefaults: 'Movimientos/onDefaults',
    }),
    ...mapMutations({
      setEdited: 'Movimientos/setEdited',
      setId: 'Movimientos/setId',
      setSalidasFecha: 'Movimientos/setSalidasFecha',
      setSalidasCantidadPropios: 'Movimientos/setSalidasCantidadPropios',
      setVentasFecha: 'Movimientos/setVentasFecha',
      setVentasCantidadPropios: 'Movimientos/setVentasCantidadPropios',
      setVentasCantidadCompetencia: 'Movimientos/setVentasCantidadCompetencia',
      setChoferId: 'Movimientos/setChoferId',
      setEnvaseId: 'Movimientos/setEnvaseId',
    }),
    onCancel () {
      this.resetEdited()
      this.$refs.form.resetValidation()
      this.$router.go(-1)
    },
    onSubmit () {
      this.$refs.form.validate()
      if(this.valid) {
        this.onSave().then(response => {
          this.dialog = false
          this.$emit('refresh', true)
          this.resetEdited()
          this.$refs.form.resetValidation()
        })
      }
    },
    onDeleteButton () {
      this.onDelete().then(response => {
        this.dialog = false
        this.$emit('refresh', true)
        this.resetEdited()
      })
    },
    resetEdited () {
      this.setChoferId(null)
      this.setEnvaseId(null)
      this.setId(null)
      this.setSalidasFecha(null)
      this.setSalidasCantidadPropios(null)
      this.setVentasFecha(null)
      this.setVentasCantidadPropios(null)
      this.setVentasCantidadCompetencia(null)
    },
    onSelect (value) {
      this.setChoferId(value.chofer_id)
      this.setEnvaseId(value.envase_id)
      this.setId(value.id)
      this.setSalidasFecha(value.salidas_fecha)
      this.setSalidasCantidadPropios(value.salidas_cantidad_propios)
      this.setVentasFecha(value.ventas_fecha)
      this.setVentasCantidadPropios(value.ventas_cantidad_propios)
      this.setVentasCantidadCompetencia(value.ventas_cantidad_competencia)
    },
  },
}
</script>
