const URL = '/ordenes_historial';

const state = {
  all: [],
  filters: {
    orden_id: null
  },
  loading: false,
  headers: [
    {
      text: 'Actualizado',
      value: 'updated_at',
      align: 'center'
    },
    {
      text: 'Autor',
      value: 'autor',
      align: 'center'
    },
    {
      text: 'Cliente',
      value: 'domicilio.cliente',
      align: 'center'
    },
    {
      text: 'Advertencia',
      value: 'observaciones',
      align: 'center'
    },
    {
      text: 'Estado',
      value: 'estado',
      align: 'center'
    },
    {
      text: 'Chofer',
      value: 'chofer',
      align: 'center'
    },
    {
      text: 'Publicado',
      value: 'published_at',
      align: 'center'
    },
  ],
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            orden_id: state.filters.orden_id,
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload.slice()
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setFiltersOrdenId (state, payload) {
    state.filters.orden_id = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
