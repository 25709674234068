const URL = '/auth';

const state = {
  user: [],
  permisos: [],
  errors: [],
  forbidden: false,
  loading: false,
  credenciales: {
    accessToken: null,
  },
  validations: {
    //
  },
  defaults: {
    access_token: null,
    token_type: null,
    expires_in: null,
  }
}

const getters = {
  accessToken () {
    return window.sessionStorage.getItem('access_token') || null
  },
  tokenType () {
    return window.sessionStorage.getItem('token_type') || null
  },
  expiresIn () {
    return window.sessionStorage.getItem('expires_in') || null
  },
  name () {
    return window.sessionStorage.getItem('name') || null
  },
  email () {
    return window.sessionStorage.getItem('email') || null
  },
  forbidden (state) {
    return state.forbidden
  },
  rules (state) {
    return state.validations
  },
  permisos (state) {
    return state.permisos
  },
  loading (state) {
    return state.loading
  },
}

const actions = {
  onLogin ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/login', {
          email: payload.email,
          password: payload.password
        })
        .then(response => {
          // commit('setUser', response.data)
          console.log(response)
          commit('setAccessToken', response.data.access_token)
          commit('setExpiresIn', response.data.expires_in)
          commit('setTokenType', response.data.token_type)

          dispatch('onPermisos')
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              commit('setErrors', error.data)
              reject(error)
            })
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onMe ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/me')
        .then(response => {
          commit('setName', response.data.name)
          commit('setEmail', response.data.email)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onRefresh ({ dispatch, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/refresh')
        .then(response => {
          // commit('setUser', response.data)
          commit('setAccessToken', response.data.access_token)
          commit('setExpiresIn', response.data.expires_in)
          commit('setTokenType', response.data.token_type)
          dispatch('onPermisos')
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

  onPermisos ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/permissions')
        .then(response => {
          commit('setPermisos', response.data)
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          commit('setPermisos', [])
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

  onLogout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/logout')
        .then(response => {
          commit('setUser', null)
          commit('setPermisos', [])
          resolve(response.data)
        })
        .catch(error => {
          commit('setErrors', error.data)
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },

}

// mutations
const mutations = {
  setUser (state, payload) {
    state.user = payload
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('access_token')
      window.sessionStorage.removeItem('token_type')
    } else {
      window.sessionStorage.setItem('access_token', payload.access_token)
      window.sessionStorage.setItem('token_type', payload.token_type)
    }
  },
  setAccessToken (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('access_token')
      state.credenciales.accessToken = null
    } else {
      window.sessionStorage.setItem('access_token', payload)
      state.credenciales.accessToken = payload
    }
  },
  setExpiresIn (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('expires_in')
    } else {
      window.sessionStorage.setItem('expires_in', payload)
    }
  },
  setName (state, payload) {
    if (window._.isNil(payload)) {
      window.sessionStorage.removeItem('name')
    } else {
      window.sessionStorage.setItem('name', payload)
    }
  },
  setEmail (state, payload) {
    if (window._.isNil(payload)) {
      window.sessionStorage.removeItem('email')
    } else {
      window.sessionStorage.setItem('email', payload)
    }
  },
  setTokenType (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('token_type')
    } else {
      window.sessionStorage.setItem('token_type', payload)
    }
  },
  setPermisos (state, payload) {
    if (_.isNil(payload)) {
      window.sessionStorage.removeItem('permissions')
    } else {
      window.sessionStorage.setItem('permissions', JSON.stringify(payload.slice()))
    }
    state.permisos = payload.slice()
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setErrors (state, payload) {
    state.errors = payload
  },
  setForbidden (state, payload) {
    state.forbidden = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
