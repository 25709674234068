<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-if="!orden.domicilio.latitud && !orden.domicilio.longitud"
        >
          mdi-map-marker-off
        </v-icon>
        <v-chip
          v-if="orden.domicilio.cliente.razon_social"
          v-on="on"
        >
          {{ orden.domicilio.cliente.razon_social }}
        </v-chip>
        <v-chip
          v-else-if="orden.domicilio.cliente.apellido"
          v-on="on"
        >
          {{ orden.domicilio.cliente.nombre + ' ' + orden.domicilio.cliente.apellido }}
        </v-chip>
      </template>

      <v-card>
        <v-toolbar
          flat
        >
          <v-btn
            text
            @click="dialog = false"
          >
            <v-icon dark>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Información</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            :to="{ name: 'clientes-form', params: { id: orden.domicilio.cliente_id } }"
            @click="dialog = false"
            v-if="$can('clientes.update')"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                :value="orden.domicilio.cliente.razon_social ? orden.domicilio.cliente.razon_social : orden.domicilio.cliente.nombre + ' ' + orden.domicilio.cliente.apellido"
                label="Cliente"
                prepend-icon="mdi-account"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="domicilio"
                label="Domicilio"
                prepend-icon="mdi-map-marker"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="observaciones"
                label="Observaciones del domicilio"
                prepend-icon="mdi-text"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="zona"
                label="Zona"
                prepend-icon="mdi-terrain"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              v-if="!orden.domicilio.latitud && !orden.domicilio.longitud"
            >
              <v-subheader>Geolocalizacion</v-subheader>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon>mdi-map-marker-off</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-wrap">No tiene registrada la ubicación del zeppelin</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    orden: {
      type: Object,
      default: null
    },
  },

  data () {
    return {
      label: 'Cliente',
      dialog: false,
    }
  },

  created () {
    //
  },

  mounted () {
    //
  },

  computed: {
    cliente () {
      return this.orden.domicilio.cliente.razon_social || this.orden.domicilio.cliente.nombre + ' ' +this.orden.domicilio.cliente.apellido || 'S/Cliente'
    },
    domicilio () {
      return this.orden.domicilio.descripcion || 'S/D'
    },
    observaciones () {
      return this.orden.domicilio.observaciones || 'S/O'
    },
    zona () {
      return this.orden.domicilio.zona ? this.orden.domicilio.zona.nombre : 'S/Z'
    },
  },

}
</script>
