<template>
  <div>
    <v-btn
      text
      @click.stop="onRefresh"
    >
      <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-cached</v-icon>
      <div class="hidden-xs-only">Permisos</div>
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {

  data () {
    return {
      //
    }
  },

  created () {
    //
  },

  computed: {
    //
  },

  watch: {
    //
  },

  methods: {
    ...mapActions({
      onRefresh: 'Auth/onPermisos',
    }),
  },
}
</script>
