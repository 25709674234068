const URL = '/movimientos_envases';

const state = {
  all: [],
  found: [],
  selected: {},
  edited: {
    id: null,
    salidas_fecha: null,
    salidas_cantidad_propios: null,
    ventas_fecha: null,
    ventas_cantidad_propios: null,
    ventas_cantidad_competencia: null,
    chofer_id: null,
    envase_id: null,
  },
  sort: {
    by: 'created_at',
    desc: 1,
    list: [
      {
        key: 'Fecha salida',
        value: 'salidas_fecha',
      },
      {
        key: 'Fecha venta',
        value: 'ventas_fecha',
      },
    ],
  },
  filters: {
    salidas: {
      from: null,
      to: null,
    },
    ventas: {
      from: null,
      to: null,
    },
    chofer_id: null,
    envase_id: null,
  },
  // sort: {
  //   collection: [
  //     'Orden',
  //     'Cliente',
  //     'Zona',
  //     'Estado'
  //   ],
  //   by: null,
  //   desc: false,
  // },
  loading: false,
  headers: [
    {
      text: '',
      value: 'id',
      align: 'center'
    },
    {
      text: 'Fecha',
      value: 'salidas_fecha',
      align: 'center'
    },
    {
      text: 'Envase',
      value: 'envase',
      align: 'center'
    },
    {
      text: 'Salidas',
      value: 'salidas_cantidad_propios',
      align: 'center'
    },
    {
      text: 'Ventas',
      value: 'ventas_cantidad_propios',
      align: 'center'
    },
    {
      text: 'En G.A.',
      value: 'ventas_cantidad_competencia',
      align: 'center'
    },
    {
      text: 'Chofer',
      value: 'chofer',
      align: 'center'
    },
  ],
  validations: {
    id: null,
    salidas_fecha: [
      v => !!v || 'Este campo es necesario',
    ],
    salidas_cantidad_propios: [
      v => !!v || 'Este campo es necesario',
    ],
    ventas_fecha: [
      // v => !!v || 'Este campo es necesario',
    ],
    ventas_cantidad_propios: [
      // v => !!v || 'Este campo es necesario',
    ],
    ventas_cantidad_competencia: [
      // v => !!v || 'Este campo es necesario',
    ],
    chofer_id: [
      v => !!v || 'Este campo es necesario',
    ],
    envase_id: [
      v => !!v || 'Este campo es necesario',
    ],
  },
  defaults: {
    id: null,
    salidas_fecha: null,
    salidas_cantidad_propios: null,
    ventas_fecha: null,
    ventas_cantidad_propios: null,
    ventas_cantidad_competencia: null,
    chofer_id: null,
    envase_id: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  anySelectedFilters (state) {
    return state.filters.salidas.from || 
          state.filters.salidas.to ||
          state.filters.ventas.from ||
          state.filters.ventas.to ? true : false;
  },
  found (state) {
    return state.found
  },
  filters (state) {
    return state.filters
  },
  sort (state) {
    return state.sort
  },
  sortList (state) {
    return state.sort.list
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            sortBy: state.sort.by,
            sortDesc: state.sort.desc,
            salidas_fecha_from: state.filters.salidas.from,
            salidas_fecha_to: state.filters.salidas.to,
            ventas_fecha_from: state.filters.ventas.from,
            ventas_fecha_to: state.filters.ventas.to,
            chofer_id: state.filters.chofer_id,
            envase_id: state.filters.envase_id,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onFind ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setEdited', response.data)
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onLoad ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onPredeterminado ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/' + payload)
        .then(response => {
          commit('setSelected', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSearch ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL + '/busqueda/' + payload)
        .then(response => {
          commit('setFound', response.data)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDefaults ({ state, commit }) {
    commit('setEdited', state.defaults)
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el movimiento', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el movimiento', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el movimiento', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el movimiento', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado el movimiento', { root: true })
          commit('setEdited', state.defaults)
          resolve(response.data)
        })
        .catch(error => {
          console.log(error);
          commit('ResponseHTTP/addError', 'Error al borrar el movimiento', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setId (state, payload) {
    state.edited.id = payload
  },
  setSalidasFecha (state, payload) {
    state.edited.salidas_fecha = payload
  },
  setSalidasCantidadPropios (state, payload) {
    state.edited.salidas_cantidad_propios = payload
  },
  setVentasFecha (state, payload) {
    state.edited.ventas_fecha = payload
  },
  setVentasCantidadPropios (state, payload) {
    state.edited.ventas_cantidad_propios = payload
  },
  setVentasCantidadCompetencia (state, payload) {
    state.edited.ventas_cantidad_competencia = payload
  },
  setChoferId (state, payload) {
    state.edited.chofer_id = payload
  },
  setEnvaseId (state, payload) {
    state.edited.envase_id = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFiltersSalidasFechaFrom (state, payload) {
    window.sessionStorage.setItem('movimientos.salidas_fecha.from', payload)
    state.filters.salidas.from = payload
  },
  setFiltersSalidasFechaTo (state, payload) {
    window.sessionStorage.setItem('movimientos.salidas_fecha.to', payload)
    state.filters.salidas.to = payload
  },
  setFiltersVentasFechaFrom (state, payload) {
    window.sessionStorage.setItem('movimientos.ventas_fecha.from', payload)
    state.filters.ventas.from = payload
  },
  setFiltersVentasFechaTo (state, payload) {
    window.sessionStorage.setItem('movimientos.ventas_fecha.to', payload)
    state.filters.ventas.to = payload
  },
  setFiltersChoferId (state, payload) {
    window.sessionStorage.setItem('movimientos.chofer_id', payload)
    state.filters.chofer_id = payload
  },
  setFiltersEnvaseId (state, payload) {
    window.sessionStorage.setItem('movimientos.envase_id', payload)
    state.filters.envase_id = payload
  },
  setSortBy (state, payload) {
    state.sort.by = payload
  },
  setSortDesc (state, payload) {
    state.sort.desc = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
