<template>
  <div>
    <my-menu
      v-model="drawer"
    ></my-menu>
    <v-app-bar
      :color="color"
      dark
      app
    >
      <my-estado-select
        v-model="estado"
      ></my-estado-select>
      <v-spacer></v-spacer>
      <my-sucursal-select
      ></my-sucursal-select>
      <v-toolbar-items>
        <v-btn text @click.stop="drawer = !drawer">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-app-bar>

    <v-main>
      <alertas-view></alertas-view>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
      <v-container>
        <my-forbidden></my-forbidden>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import AlertasView from '@/components/AlertasView'
import PermisosRefresh from '@/components/PermisosRefresh'
import EstadoSelect from '@/pages/estados/TabsSelect'
import Forbidden from '@/components/Forbidden'
import SucursalSelect from '@/pages/sucursales/Select'
import Menu from '@/pages/layouts/Menu'
export default {
  components: {
    'alertas-view': AlertasView,
    'my-permiso-refresh': PermisosRefresh,
    'my-forbidden': Forbidden,
    'my-estado-select': EstadoSelect,
    'my-sucursal-select': SucursalSelect,
    'my-menu': Menu,
  },
  data () {
    return {
      drawer: false,
      messaging: null,
      menuOrdenes: false,
      menuOrdenesChoferes: false,
    }
  },
  created () {
    // this.onEstadoInit()
  },
  computed: {
    ...mapGetters({
      user_name: 'Auth/name',
      email: 'Auth/email',
      filters: 'Ordenes/filters',
    }),
    username () {
      return this.user_name ? this.user_name.split(' ')[0] : 'Ninguno'
    },
    title () {
      return this.$route.meta.title || 'Sin autorización'
    },
    color () {
      return this.$route.meta.color || 'toolbar'
    },
    estado: {
      get () {
        return this.filters.estado_id
      },
      set (value) {
        this.setFiltersEstadoId(value)
        this.setPage(1)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Ordenes/onFetch',
      onLogout: 'Auth/onLogout',
      onEstadoInit: 'Estados/onFetch',
    }),
    ...mapMutations({
      setPage: 'Ordenes/setPage',
      setFiltersEstadoId: 'Ordenes/setFiltersEstadoId',
    }),
    onSubmitLogout () {
      this.onLogout()
        .then(
          this.$router.push({ name: 'login' })
        )
    },
  }
}
</script>