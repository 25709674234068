<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"      
      >
        <v-container>
          <v-toolbar flat>
            <v-btn
              text
              @click="onBack"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-chevron-left</v-icon>
              <div class="hidden-xs-only">{{ backButtonText }}</div>
            </v-btn>
            <v-spacer></v-spacer>
            <!-- mdi-google-maps -->
            <v-btn
              text
              color="primary"
              @click.stop="onSubmit"
              :loading="loading"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-check</v-icon>
              <div class="hidden-xs-only">{{ submitButtonText }}</div>
            </v-btn>
          </v-toolbar>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="orden"
                placeholder="Ingrese un número de orden"
                label="Número de orden"
                :rules="rules.orden"
                prepend-icon="mdi-counter"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="descripcion"
                placeholder="Ingrese un descripcion"
                prepend-icon="mdi-map-marker"
                label="Descripcion"
                :rules="rules.descripcion"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                placeholder="Ingrese un nombre"
                :rules="rules.nombre"
                prepend-icon="mdi-label"
                label="Nombre"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <my-color-picker
                v-model="color"
                label="Color"
              ></my-color-picker>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="icono"
                placeholder="Ingrese un icono"
                :rules="rules.icono"
                prepend-icon="mdi-label"
                label="Icono"
                color="primary"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-icon
                v-if="icono"
                dark
                size="64"
                :style="'color:' + color"
                v-html="icono"
              ></v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ColorPicker from '@/components/ColorPicker'
export default {
  components: {
    'my-color-picker': ColorPicker
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      backButtonText: 'Volver',
      redirect: 'estados-list',
    }
  },
  created () {
    this.onDefaults()
  },
  computed: {
    ...mapGetters({
      edited: 'Estados/edited',
      rules: 'Estados/rules',
      loading: 'Estados/loading',
    }),
    orden: {
      get () {
        return this.edited.orden
      },
      set (value) {
        this.setOrden(value)
      }
    },
    nombre: {
      get () {
        return this.edited.nombre
      },
      set (value) {
        this.setNombre(value)
      }
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setDescripcion(value)
      }
    },
    color: {
      get () {
        return this.edited.color || '#FF00FFFF'
      },
      set (value) {
        this.setColor(value)
      }
    },
    icono: {
      get () {
        return this.edited.icono
      },
      set (value) {
        this.setIcono(value)
      }
    },
  },
  methods: {
    ...mapActions({
      onSave: 'Estados/onSave',
      onDefaults: 'Estados/onDefaults',
    }),
    ...mapMutations({
      setOrden: 'Estados/setOrden',
      setNombre: 'Estados/setNombre',
      setDescripcion: 'Estados/setDescripcion',
      setColor: 'Estados/setColor',
      setIcono: 'Estados/setIcono',
    }),
    onBack () {
      this.$router.push({ name: this.redirect })
    },
    onSubmit () {
      this.onSave().then(response => {
        this.$router.push({ name: this.redirect })
      })
    },
  },
}
</script>
