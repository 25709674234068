const URL = '/clientes';

const state = {
  all: [],
  selected: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    inactivo: null,
  },
  filters: {
    searchText: null,
  },
  edited: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    inactivo: null,
  },
  loading: false,
  headers: [
    {
      text: 'Documento',
      value: 'documento',
      align: 'center'
    },
    {
      text: 'Apellido y nombre',
      value: 'razon_social',
      align: 'center'
    },
    // {
    //   text: 'Email',
    //   value: 'email',
    //   align: 'center'
    // },
    {
      text: 'Activo',
      value: 'inactivo',
      align: 'center'
    },
  ],
  validations: {
    cliente_id: [
      v => !!v || 'Este campo es necesario',
    ],
    documento: [
      v => !!v || 'Este campo es necesario',
    ],
    razon_social: [
      v => !!v || 'Este campo es necesario',
    ],
    email: [
      // v => !!v || 'Este campo es necesario',
    ],
    telefono_uno: [
      // v => !!v || 'Este campo es necesario',
    ],
    telefono_dos: [
      // v => !!v || 'Este campo es necesario',
    ],
    inactivo: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  defaults: {
    id: null,
    documento: null,
    razon_social: null,
    apellido: null,
    nombre: null,
    email: null,
    telefono_uno: null,
    telefono_dos: null,
    inactivo: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  edited (state) {
    return state.edited
  },
  filters (state) {
    return state.filters
  },
  selected (state) {
    return state.selected
  },
  isSelected (state) {
    return !!state.selected.id
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onClearSelected ({ commit }) {
    commit('setSelectedId', null)
    commit('setSelectedDocumento', null)
    commit('setSelectedRazonSocial', null)
    commit('setSelectedEmail', null)
    commit('setSelectedTelefonoUno', null)
    commit('setSelectedTelefonoDos', null)
    commit('setSelectedInactivo', null)
  },
  onClearEdited ({ commit }) {
    commit('setEditedId', null)
    commit('setEditedDocumento', null)
    commit('setEditedRazonSocial', null)
    commit('setEditedEmail', null)
    commit('setEditedTelefonoUno', null)
    commit('setEditedTelefonoDos', null)
    commit('setEditedInactivo', null)
  },
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
        params: {
          itemsPerPage: state.options.itemsPerPage,
          page: state.options.page,
          searchText: state.filters.searchText,
          sortBy: state.options.sortBy[0],
          sortDesc: state.options.sortDesc[0] ? 1 : 0,
        }
      })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          if (state.options.page + state.options.itemsPerPage > response.data.meta.total)
            commit('setPage', 1)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          commit('setEditedId', response.data.id)



          // this.setEditedId(data.id)
          // this.setSelectedId(data.id)
          // this.setSelectedDocumento(data.documento)
          // this.setSelectedRazonSocial(data.razon_social)
          // this.setSelectedEmail(data.email)
          // this.setSelectedTelefonoUno(data.telefono_uno)
          // this.setSelectedTelefonoDos(data.telefono_dos)


          commit('ResponseHTTP/addSuccess', 'Se ha guardado el cliente', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el cliente', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setEdited (state, payload) {
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setEditedId (state, payload) {
    state.edited.id = payload
  },
  setEditedDocumento (state, payload) {
    state.edited.documento = payload
  },
  setEditedRazonSocial (state, payload) {
    state.edited.razon_social = payload
  },
  setEditedEmail (state, payload) {
    state.edited.email = payload
  },
  setEditedTelefonoUno (state, payload) {
    state.edited.telefono_uno = payload
  },
  setEditedTelefonoDos (state, payload) {
    state.edited.telefono_dos = payload
  },
  setEditedInactivo (state, payload) {
    state.edited.inactivo = payload
  },
  setSelectedId (state, payload) {
    state.selected.id = payload
  },
  setSelectedDocumento (state, payload) {
    state.selected.documento = payload
  },
  setSelectedRazonSocial (state, payload) {
    state.selected.razon_social = payload
  },
  setSelectedEmail (state, payload) {
    state.selected.email = payload
  },
  setSelectedTelefonoUno (state, payload) {
    state.selected.telefono_uno = payload
  },
  setSelectedTelefonoDos (state, payload) {
    state.selected.telefono_dos = payload
  },
  setSelectedInactivo (state, payload) {
    state.selected.inactivo = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
