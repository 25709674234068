<template>
  <div>
    <v-divider
      v-if="showFilters"
    ></v-divider>
    <v-sheet
      v-if="showFilters"
    >
      <v-toolbar flat>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onClearFilters"
          :disabled="anySelectedFilters"
          :loading="loading"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="3">
            <my-date-picker
              label="Salidas desde el"
              v-model="salidasFechaFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Salidas hasta el"
              v-model="salidasFechaTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="3">
            <my-envase-select
              v-model="envase"
              clearable
            ></my-envase-select>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-select
              v-model="sortBy"
              item-text="key"
              item-value="value"
              :items="sortList"
              label="Ordernar por"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <div class="text-right">
              <v-radio-group
                v-model="sortDesc"
                row
              >
                <v-radio
                  label="Ascendente"
                  :value="0"
                ></v-radio>
                <v-radio
                  label="Descendente"
                  :value="1"
                ></v-radio>
              </v-radio-group>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-navigation-drawer
      permanent
      app
    >
      <template v-slot:prepend>
        <div class="pa-2" v-if="$can('ordenes.store')">
          <v-btn
            block
            class="primary"
            :to="{ name: 'envases-create' }">
            <v-icon left>mdi-plus</v-icon>
            Nuevo movimiento
          </v-btn>
        </div>
      </template>
      <v-list
        nav
      >
        <my-chofer-select
          v-model="chofer"
          list
          clearable
        ></my-chofer-select>
        <v-divider></v-divider>
        <v-list-item
          v-if="$can('ordenes.index')"
          @click="onFetch"
          :loading="loading"
        >
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters">
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
        <v-list-item
          v-if="$can('menu.estadisticas.choferes')"
          :to="{ name: 'estadisticas-envases' }"
        >
          <v-list-item-content>
            <v-list-item-title>Estadísticas</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-chart-box</v-icon>
          </v-list-item-icon>
        </v-list-item>
      <!-- <template v-slot:append>
        <div class="pa-2">
          <v-btn
            block
            color="black"
            class="white--text">
            <v-icon left>mdi-cog</v-icon>
            Configuración
          </v-btn>
        </div>
      </template> -->
    </v-navigation-drawer>
    <!-- <v-main> -->
    <v-sheet></v-sheet>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="collection"
      :search="search"
      single-select
      :show-select="false"
      :footer-props="{
        itemsPerPageText: 'Filas por página',
        itemsPerPageOptions: [10,15,30,50,100]
      }"
      :loading="loading"
      :options.sync="options"
      :server-items-length="itemsLength"
      :items-per-page="15"
      disable-sort
    >
      <template v-slot:item.updated_at="{ item }">
        <div
          v-if="item.updated_at"
        >
          <v-chip
            v-if="$can('ordenes_historial.index')"
            link
            outlined
            @click.stop="onHistory(item.id)"
          >
            <v-icon left small>mdi-clock</v-icon>
            {{ onTime(item.updated_at) }}
          </v-chip>
          <v-chip
            v-else
            link
            outlined
          >
            <v-icon left small>mdi-clock</v-icon>
            {{ onTime(item.updated_at) }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.id="{ item }">
        <div v-if="item.id">
          <my-movimiento-edit
            :movimiento="item"
            @refresh="onRefresh"
          ></my-movimiento-edit>
        </div>
      </template>
      <template v-slot:item.salidas_fecha="{ item }">
        <div v-if="item.salidas_fecha">{{ item.salidas_fecha | moment('DD/MM/YYYY') }}</div>
      </template>
      <template v-slot:no-results>
        <v-alert :value="true" color="error" icon="mdi-alert">
          No se ha encontrado ningún resultado para "{{ search }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="warning" icon="mdi-alert">
          No hay información de ordenes para mostrar.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
    <!-- </v-app> -->
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ChoferSelect from '@/pages/choferes/Select'
import DatePicker from '@/components/DatePicker'
import EnvaseSelect from '@/pages/envases/Select'
import MovimientoEdit from '@/pages/movimientos/Edit'

export default {
  components: {
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker,
    'my-envase-select': EnvaseSelect,
    'my-movimiento-edit': MovimientoEdit,
  },
  data () {
    return {
      title: 'Movimientos',
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      today: null,
      yesterday: null,
      lastYear: null,
    }
  },
  created () {
    this.today = this.$moment().format('YYYY-MM-DD')
    this.yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.lastYear = this.$moment().subtract(1, 'years').format('YYYY')
  },
  computed: {
    //
    ...mapGetters({
      collection: 'Movimientos/all',
      anySelectedFilters: 'Movimientos/anySelectedFilters',
      filters: 'Movimientos/filters',
      headers: 'Movimientos/headers',
      loading: 'Movimientos/loading',
      itemsLength: 'Movimientos/itemsLength',
      optionsGetter: 'Movimientos/options',
      sortList: 'Movimientos/sortList',
      sort: 'Movimientos/sort',
    }),
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        this.onFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        this.onFetch()
      }
    },
    envase: {
      get () {
        return this.filters.envase_id
      },
      set (value) {
        this.setFiltersEnvaseId(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    chofer: {
      get () {
        return this.filters.chofer_id
      },
      set (value) {
        this.setFiltersChoferId(value)
        this.setChoferId(value)
        this.setPage(1)
        this.onFetch()
      }
    },
    salidasFechaTo: {
      get () {
        return this.filters.salidas.to
      },
      set (value) {
        this.setFiltersSalidasFechaTo(value)
        this.onFetch()
      }
    },
    salidasFechaFrom: {
      get () {
        return this.filters.salidas.from
      },
      set (value) {
        this.setFiltersSalidasFechaFrom(value)
        this.onFetch()
      }
    },
    ventasFechaTo: {
      get () {
        return this.filters.ventas.to
      },
      set (value) {
        this.setFiltersVentasFechaTo(value)
        this.onFetch()
      }
    },
    ventasFechaFrom: {
      get () {
        return this.filters.ventas.from
      },
      set (value) {
        this.setFiltersVentasFechaFrom(value)
        this.onFetch()
      }
    },
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions({
      onFetch: 'Movimientos/onFetch',
      onEstadoInit: 'Estados/onFetch',
    }),
    ...mapMutations({
      setOptions: 'Movimientos/setOptions',
      setPage: 'Movimientos/setPage',
      setFiltersChoferId: 'Movimientos/setFiltersChoferId',
      setFiltersEnvaseId: 'Movimientos/setFiltersEnvaseId',
      setFiltersSalidasFechaFrom: 'Movimientos/setFiltersSalidasFechaFrom',
      setFiltersSalidasFechaTo: 'Movimientos/setFiltersSalidasFechaTo',
      setChoferId: 'Movimientos/setChoferId',
      setSortBy: 'Movimientos/setSortBy',
      setSortDesc: 'Movimientos/setSortDesc',

    }),
    onReasignar () {
      this.$router.push({ name: 'ordenes-reasignar', params: { id: this.chofer.id } })
    },
    onClearFilters () {
      this.setFiltersEnvaseId(null)
      this.setFiltersChoferId(null)
      this.setFiltersSalidasFechaFrom(null)
      this.setFiltersSalidasFechaTo(null)
      this.setFiltersVentasFechaFrom(null)
      this.setFiltersVentasFechaTo(null)
      this.onFetch()
    },
    onClear () {
      this.setFiltersCliente(null)
      this.onFetch()
    },
    onTime (value) {
      let milis = this.$moment(value)
      return milis.isBefore(this.yesterday) ? milis.format('dddd, DD/MM') : milis.isBefore(this.today) ? 'Ayer' : milis.fromNow()
      // return milis.isBefore(this.yesterday) ? milis.format('dddd, DD/MM') : milis.isBefore(this.today) ? 'Ayer' : milis.format('HH:mm')
    },
    onRefresh() {
      this.onFetch()
    },
    onHistory (value) {
      this.$router.push({ name: 'ordeneshist-datatable', params: { id: value } })
    }
  }
}
</script>
