<template>
  <div>
    <v-divider
      v-if="showFilters"
    ></v-divider>
    <v-sheet
      v-if="showFilters"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showFilters = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Filtros</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="onClearFilters"
          :disabled="anySelectedFilters"
          :loading="loadingLocal"
        >
          <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-filter-variant-remove</v-icon>
          <div class="hidden-xs-only">Limpiar filtros</div>
        </v-btn>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Publicado desde el"
              v-model="publishedFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Publicado hasta el"
              v-model="publishedTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Creados desde el"
              v-model="createdFrom"
              clearable
            ></my-date-picker>
            <my-date-picker
              label="Creados hasta el"
              v-model="createdTo"
              clearable
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-zona-select
              v-model="zona"
              clearable
            ></my-zona-select>
            <v-text-field
              v-model="cliente"
              label="Cliente"
              messages="Enter para buscar"
              clearable
              prepend-icon="mdi-account"
              @keydown.enter="onActualizar"
              @click:clear="onClear"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider
      v-if="showOrdering"
    ></v-divider>
    <v-sheet
      v-if="showOrdering"
    >
      <v-toolbar flat>
        <v-btn
          icon
          @click.stop="showOrdering = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ordenamiento</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-chip-group
              v-model="chipSelected"
              column
              active-class="primary"
            >
              <v-chip @click.stop="onOrdenamientoPorDefecto(0)">Por defecto</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicadosAntiguosPendientes(1)">Publicados antiguos y pendientes</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicadosRecientesPendientes(2)">Publicados recientes y pendientes</v-chip>
              <v-chip @click.stop="onOrdenamientoActualizacionesRecientes(3)">Actualizaciones recientes</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicados(1, 4)">Publicados recientemente</v-chip>
              <v-chip @click.stop="onOrdenamientoPublicados(0, 5)">Publicados antiguos</v-chip>
            </v-chip-group>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-divider></v-divider>
    <v-navigation-drawer
      permanent
      app
      width="300px"
    >
      <v-list nav>
        <my-chofer-select
          v-model="chofer"
          list
          clearable
          @sinasignar="onSinAsignar"
        ></my-chofer-select>
        <v-divider></v-divider>
        <v-list-item
          v-if="$can('ordenes.store')"
          :to="{ name: 'ordenes-create' }"
          class="primary"
          dark
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f1</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Nueva orden</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('ordenes.index')"
          @click="onReasignar"
          :disabled="chofer == null || chofer == undefined"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f2</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Reasignar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-truck-trailer</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          link
          :class="showFilters ? 'primary--text' : ''"
          @click.stop="showFilters = !showFilters"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f3</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Filtros</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-filter-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          link
          :class="showOrdering ? 'primary--text' : ''"
          @click.stop="showOrdering = !showOrdering">
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f4</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Ordenamiento</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-sort-variant</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('ordenes.index')"
          @click="onActualizar"
          :loading="loadingLocal"
        >
          <v-list-item-icon>
            <v-icon>mdi-keyboard-f5</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Actualizar</v-list-item-title>
          <v-list-item-icon>
            <v-icon>mdi-refresh</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-if="$can('menu.estadisticas.choferes')"
          :to="{ name: 'estadisticas-ordenes' }"
        >
          <v-list-item-content>
            <v-list-item-title>Estadísticas</v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon>mdi-chart-box</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="all"
        :search="search"
        single-select
        :show-select="false"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
          itemsPerPageOptions: [10,15,30,50,100]
        }"
        :loading="loadingLocal"
        :options.sync="optionsLocal"
        :server-items-length="itemsLength"
        :items-per-page="15"
        disable-sort
      >
        <template v-slot:item.domicilio.cliente="{ item }">
          <my-cliente-predeterminado
            :orden="item"
          ></my-cliente-predeterminado>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div
            v-if="item.updated_at"
          >
            <v-chip
              v-if="$can('ordenes_historial.index')"
              link
              outlined
              @click.stop="onHistory(item.id)"
            >
              <v-icon
                left
                small
                v-text="'mdi-clock'"
              ></v-icon>
              {{ onTime(item.updated_at) }}
            </v-chip>
            <v-chip
              v-else
              link
              outlined
            >
              <v-icon
                left
                small
                v-text="'mdi-clock'"
              ></v-icon>
              {{ onTime(item.updated_at) }}
            </v-chip>
          </div>
        </template>
        <!--
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.created_at">
            <div v-if="monthDescription">
              {{ item.created_at | moment(momentFormat) }}
            </div>
            <div v-else>
              {{ item.created_at | moment('dddd, DD/MM') }}
            </div>
          </div>
        </template>
        -->
        <template v-slot:item.published_at="{ item }">
          <my-publicado-predeterminado
            chip
            :orden="item"
            @refresh="onRefresh"
            min-today
          ></my-publicado-predeterminado>
        </template>
        <template v-slot:item.estado="{ item }">
          <my-estado-predeterminado
            :orden="item"
            @refresh="onRefresh"
            chip
          ></my-estado-predeterminado>
        </template>
        <template v-slot:item.chofer="{ item }">
          <my-chofer-predeterminado
            v-if="item.chofer"
            :orden="item"
            @refresh="onRefresh"
            with-nombre
          ></my-chofer-predeterminado>
          <my-chofer-predeterminado
            v-else
            :orden="item"
            @refresh="onRefresh"
            with-nombre
          ></my-chofer-predeterminado>
        </template>
        <template v-slot:item.advertencia="{ item }">
          <my-advertencia-predeterminado
            :orden="item"
            @refresh="onRefresh"
          ></my-advertencia-predeterminado>
        </template>
        <!--
        <template v-slot:item.id="{ item }">
          <div v-if="item.id">
            <v-icon @click="">mdi-text-box</v-icon>
          </div>
        </template>
        -->
        
        <template v-slot:item.updated_at="{ item }">
          <div
            v-if="item.updated_at"
          >
            <v-chip
              v-if="$can('ordenes_historial.index')"
              link
              outlined
              @click.stop="onHistory(item.id)"
            >
              <v-icon
                left
                small
                v-text="'mdi-clock'"
              ></v-icon>
              {{ onTime(item.updated_at) }}
              </v-chip>
              <v-chip
                v-else
                link
                outlined
              >
                <v-icon
                  left
                  small
                  v-text="'mdi-clock'"
                ></v-icon>
                {{ onTime(item.updated_at) }}
              </v-chip>
            </div>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="mdi-alert">
            No se ha encontrado ningún resultado para "{{ search }}".
          </v-alert>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" icon="mdi-alert">
            No hay información de ordenes para mostrar.
          </v-alert>
        </template>
        <template v-slot:pageText="props">
          Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
      </v-data-table>
      <!--
      <v-divider></v-divider>
      <my-ordenes-map></my-ordenes-map>
      -->
      <!-- Evntos del componente sucursal -->
      <my-eventbus></my-eventbus>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import ChoferPredeterminado from '@/pages/choferes/Predeterminado'
import ChoferSelect from '@/pages/choferes/Select'
import ClientePredeterminado from '@/pages/clientes/Predeterminado'
import DatePicker from '@/components/DatePicker'
import EstadoPredeterminado from '@/pages/estados/Predeterminado'
import EstadoSelect from '@/pages/estados/Select'
import AdvertenciaPredeterminado from '@/pages/advertencias/Predeterminado'
import PublicadoPredeterminado from '@/pages/publicado/Predeterminado'
// import OrdenesMap from '@/pages/ordenes/Map'
import ZonaSelect from '@/pages/zonas/Select'
import EventBus from '@/pages/ordenes/EventBus'

export default {
  components: {
    'my-eventbus': EventBus,
    'my-chofer-predeterminado': ChoferPredeterminado,
    'my-chofer-select': ChoferSelect,
    'my-cliente-predeterminado': ClientePredeterminado,
    'my-date-picker': DatePicker,
    'my-estado-select': EstadoSelect,
    'my-advertencia-predeterminado': AdvertenciaPredeterminado,
    'my-estado-predeterminado': EstadoPredeterminado,
    'my-publicado-predeterminado': PublicadoPredeterminado,
    'my-zona-select': ZonaSelect,
    // 'my-ordenes-map': OrdenesMap,
  },
  data () {
    return {
      chipSelected: 0,
      search: '',
      selected: [],
      showFilters: false,
      showOrdering: false,
      monthDescription: false,
      momentFormat: '',
      today: null,
      yesterday: null,
      lastYear: null
    }
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
    // Captura el evento 'selectedUpdated' emitido por el store
    // this.eventBus = this.$root.$options.provide.eventbus;
    // this.eventBus.$on('sucursal_id_selected', this.handleSucursalIdSelected);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    // this.eventBus.$off('sucursal_id_selected', this.handleSucursalIdSelected);
  },
  created () {
    this.today = this.$moment().format('YYYY-MM-DD')
    this.yesterday = this.$moment().subtract(1, 'days').format('YYYY-MM-DD')
    this.lastYear = this.$moment().subtract(1, 'years').format('YYYY')
  },
  computed: {
    ...mapGetters('Ordenes', ['all', 'anySelectedFilters', 'anySelectedOrdering', 'filters', 'headers',
                 'loading', 'itemsLength', 'options', 'sortList', 'sort']),
    loadingLocal: {
      get () {
        return this.loading
      },
      set (value) {
        this.setLoading(value)
      }
    },
    sortBy: {
      get () {
        return this.sort.by
      },
      set (value) {
        this.setSortBy(value)
        console.log('sortBy')
        this.onSubmitFetch()
      }
    },
    sortDesc: {
      get () {
        return this.sort.desc
      },
      set (value) {
        this.setSortDesc(value)
        console.log('sortDesc')
        this.onSubmitFetch()
      }
    },
    estado: {
      get () {
        return this.filters.estado_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          estado_id: value
        })
        this.setPage(1)
        
        console.log('estado')
        this.onSubmitFetch()
      }
    },
    chofer: {
      get () {
        return this.filters.chofer_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          chofer_id: value,
          sinAsignar: null
        })

        this.setChoferId(value)
        this.setPage(1)
        console.log('chofer')
        this.onSubmitFetch()
      }
    },
    cliente: {
      get () {
        return this.filters.cliente
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          cliente: value
        })
      }
    },
    zona: {
      get () {
        return this.filters.zona_id
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          zona_id: value
        })
        this.setPage(1)
        console.log('zona')
        this.onSubmitFetch()
      }
    },
    createdFrom: {
      get () {
        return this.filters.created_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          created_from: value
        })
        console.log('createdFrom')
        this.onSubmitFetch()
      }
    },
    createdTo: {
      get () {
        return this.filters.created_to
      },
      set (value) {     
        this.setFilters({
          ...this.filters,
          created_to: value
        })
        console.log('createdTo')
        this.onSubmitFetch()
      }
    },
    publishedFrom: {
      get () {
        return this.filters.published_from
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          published_from: value
        })
        console.log('publishedFrom')
        this.onSubmitFetch()
      }
    },
    publishedTo: {
      get () {
        return this.filters.published_to
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          published_to: value
        })
        console.log('publishedTo')
        this.onSubmitFetch()
      }
    },
    sinAsignar: {
      get () {
        return this.filters.sinAsignar
      },
      set (value) {
        const aux = value ? true : null
        this.setFilters({
          ...this.filters,
          sinAsignar: aux,
          chofer_id: null
        })
        console.log('sinAsignar')
        this.onSubmitFetch()
      }
    },
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        console.log('optionsLocal')
        this.onSubmitFetch()
      }
    },
  },

  methods: {
    ...mapActions('Ordenes', ['onFetch', 'onClearFilters']),
    ...mapActions({ onEstadoInit: 'Estados/onFetch' }),
    
    ...mapMutations('Ordenes', ['setOptions', 'setPage', 'setChoferId',
           'setPublishedAt', 'setFilters', 'setSortBy', 'setSortDesc', 'setLoading']),
    onActualizar (event) {
      event.preventDefault()
      console.log('onActualizar')
      this.onSubmitFetch()
    },
    onOrdenamientoPublicadosAntiguosPendientes (chip) {
      this.estado = 1
      this.setSortBy('published_at');
      this.setSortDesc(0);
      console.log('onOrdenamientoPublicadosAntiguosPendientes')
      this.onSubmitFetch();
      this.chipSelected = chip;
    },
    onOrdenamientoPublicadosRecientesPendientes (chip) {
      this.estado = 1
      this.setSortBy('published_at');
      this.setSortDesc(1);      
      console.log('onOrdenamientoPublicadosRecientesPendientes')
      this.onSubmitFetch();
      this.chipSelected = chip;
    },
    onOrdenamientoActualizacionesRecientes (chip) {
      this.estado = null
      this.setSortBy('updated_at');
      this.setSortDesc(1);
      console.log('onOrdenamientoActualizacionesRecientes')
      this.onSubmitFetch();
      this.chipSelected = chip;
    },
    onOrdenamientoPublicados (sortDesc, chip) {
      this.estado = null
      this.zona = null
      this.createdFrom = null
      this.createdTo = null
      this.setSortBy('published_at');
      this.setSortDesc(sortDesc);
      console.log('onOrdenamientoPublicados')
      this.onSubmitFetch();
      this.chipSelected = chip;
    },
    onOrdenamientoPorDefecto (chip) {
      this.cliente = null
      this.domicilio = null
      this.estado = null
      this.chofer = null
      this.zona = null
      this.createdFrom = null
      this.createdTo = null
      this.setSortBy('published_at');
      this.setSortDesc(1);
      console.log('onOrdenamientoPorDefecto')
      this.onSubmitFetch();
      this.chipSelected = chip;
    },
    onReasignar () {
      this.$router.push({ name: 'ordenes-reasignar', params: { id: this.chofer } })
    },
    onClearFilters () {
      // this.cliente = null
      // this.domicilio = null
      // this.estado = null
      // this.chofer = null
      // this.zona = null
      // this.createdFrom = null
      // this.createdTo = null
      // this.publishedFrom = null
      // this.publishedTo = null
      this.onClearFilters()
      console.log('onClearFilters')
      this.onSubmitFetch()
    },
    onClear () {
      this.setFiltersCliente(null)
      console.log('onClear')
      this.onSubmitFetch()
    },
    onTime (value) {
      let milis = this.$moment(value)
      return milis.isBefore(this.yesterday) ? milis.format('dddd, DD/MM') : milis.isBefore(this.today) ? 'Ayer' : milis.fromNow()
    },
    onRefresh() {
      console.log('onRefresh')
      this.onSubmitFetch()
    },
    onHistory (value) {
      this.$router.push({ name: 'ordeneshist-datatable', params: { id: value } })
    },
    onSinAsignar(value) {
      this.sinAsignar = value
    },
    handleKeyboard(event) {
      if(event.key === "F1" && !event.ctrlKey) {
        event.preventDefault()
        this.$router.push({ name: 'ordenes-create', params: { id: this.chofer } })
      }
      else if(event.key === "F2" && !event.ctrlKey && this.chofer != null && this.chofer != undefined) {
        event.preventDefault()
        this.$router.push({ name: 'ordenes-reasignar', params: { id: this.chofer } })
      }
      else if(event.key === "F3" && !event.ctrlKey) {
        event.preventDefault()
        this.showFilters = !this.showFilters
      }
      else if(event.key === "F4" && !event.ctrlKey) {
        event.preventDefault()
        this.showOrdering = !this.showOrdering
      }
      else if(event.key === "F5" && !event.ctrlKey) {
        event.preventDefault()
        console.log('F5')
        this.onSubmitFetch()
      }
    },
    handleSucursalIdSelected (value) {
      alert(value)
    },
    onSubmitFetch () {
      this.onFetch()
    },
  }
}
</script>
