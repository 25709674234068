<template>
  <div>
    <v-sheet>
      <v-toolbar flat>
        <v-toolbar-title>Rendimiento de choferes</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Publicado desde el"
              v-model="publishedFrom"
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Publicado hasta el"
              v-model="publishedTo"
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-chofer-select
              v-model="chofer"
              first
            ></my-chofer-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="(item, index) in resultados"
            :key="index"
            cols="3"
          >
            <v-card>
              <v-card-text class="text-center">
                <v-chip
                  :color="item.color"
                  dark
                >
                  <v-icon
                    left
                    small
                    v-text="item.icono"
                  ></v-icon>
                  {{ item.nombre }}
                </v-chip>
              </v-card-text>
              <v-card-text class="text-center">
                <h1>{{ item.selected }}/{{ item.all }}</h1>
                <div>Seleccionado/Todos</div>
              </v-card-text>
              <v-card-text
                v-if="item.selected > 0 "
                class="text-center"
              >
                <h1>{{ item.selected / item.all | percent }}</h1>
                <div>Representa</div>
              </v-card-text>
              <v-card-text
                v-else
                class="text-center"
              >
                <h1>0%</h1>
                <div>Representa</div>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col cols="12">
            <apexchart type="bar" height="430" :options="chartOptions" :series="series"></apexchart>
          </v-col> -->
        </v-row>
        <v-row>
          <v-col cols="12">
            <div><b>Seleccionado:</b> cantidad de órdenes de distribución que tiene asignado el chofer seleccionado. Se puede cambiar desde el campo Chofer (arriba a la derecha).</div>
            <div><b>Todos:</b> cantidad de órdenes de distribucción de todos los choferes (incluido los que están Sin Asignar).</div>
            <div><b>Representa:</b> porcentaje que representa la cantidad de òrdenes del chofer seleccionado sobre la cantidad total de órdenes de todos los choferes.</div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import ChoferSelect from '@/pages/choferes/Select'
export default {
  components: {
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
    }
  },
  filters: {
    percent: (dec) => {
      console.log(_.floor(dec, 2))
      return _.floor(_.multiply(dec, 100), 0) + '%'
    }
  },
  created () {
    this.publishedFrom = this.$moment().subtract(30, 'days').format('YYYY-MM-DD')
    this.publishedTo = this.$moment().format('YYYY-MM-DD')
  },
  mounted () {
    // this.onDefaults()
    // if (this.$route.params.id)
    //   this.onFind(this.$route.params.id)
    // this.onChoferes()
  },
  computed: {
    ...mapGetters({
      filters: 'Estadisticas/filters',
      labels: 'Estadisticas/labels',
      series: 'Estadisticas/series',
      loading: 'Estadisticas/loading',
      resultados: 'Estadisticas/resultados',
    }),
    dates () {
      return [
        this.publishedFrom, this.publishedTo
      ]
    },
    chartOptions () {
      return {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: this.labels,
        },
      }
    },
    chofer: {
      get () {
        return this.filters.chofer_id
      },
      set (value) {
        this.setFiltersChoferId(value)
        // this.onChoferes()
        this.onSubmit()
      }
    },
    publishedFrom: {
      get () {
        return this.filters.published.from
      },
      set (value) {
        this.setFiltersPublishedFrom(value)
        // this.onChoferes()
        this.onSubmit()
      }
    },
    publishedTo: {
      get () {
        return this.filters.published.to
      },
      set (value) {
        this.setFiltersPublishedTo(value)
        this.onSubmit()
      }
    },
  },
  methods: {
    ...mapActions({
      onChoferes: 'Estadisticas/onChoferes',
    }),
    ...mapMutations({
      setFiltersChoferId: 'Estadisticas/setFiltersChoferId',
      setFiltersPublishedFrom: 'Estadisticas/setFiltersPublishedFrom',
      setFiltersPublishedTo: 'Estadisticas/setFiltersPublishedTo',
    }),
    onCancel () {
      this.$router.go(-1)
    },
    onSubmit () {
      if (this.publishedTo && this.publishedFrom && this.chofer)
        this.onChoferes()
    },
  },
}
</script>