<template>
  <div>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="40"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :readonly="readonly"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="selectedLocal"
          :label="label"
          :placeholder="placeholder"
          prepend-icon="mdi-color"
          readonly
          :rules="rules"
          v-bind="attrs"
          v-on="on"
        >
         <template v-slot:prepend>
          <v-avatar
            size="22"
            :style="'background-color:' + selectedLocal"
          >
          </v-avatar>
        </template>
        </v-text-field>
      </template>
      <v-toolbar>
        <v-btn
          icon
          @click.stop="menu = !menu"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title></v-toolbar-title>
      </v-toolbar>
      <v-color-picker
        v-model="selectedLocal"
        :mode.sync="mode"
      ></v-color-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: String,
    label: String,
    placeholder: String,
    readonly: {
      type: Boolean,
      default: false
    },
    rules: Array,
    clearable: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      menu: false,
      mode: 'hexa',
    }
  },

  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    imageColor () {
      return 'https://ui-avatars.com/api/?bold=true&background=' + this.selectedLocal + '&name=Color'
    },
  },
}
</script>
