<template>
  <div>
    <v-sheet>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="collection"
        :search="search"
        single-select
        :show-select="false"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
          itemsPerPageOptions: [10,15,30,50,100]
        }"
        :loading="loading"
        :options.sync="options"
        :server-items-length="itemsLength"
        :items-per-page="15"
        disable-sort
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn
              text
              @click.stop="onBack"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              <div>Volver</div>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updated_at">
            {{ item.updated_at | moment('DD/MM/YYYY HH:mm') }}
          </div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.created_at">
            {{ item.created_at | moment('DD/MM/YYYY HH:mm') }}
          </div>
        </template>
        <template v-slot:item.published_at="{ item }">
          <div v-if="item.published_at">
            {{ item.published_at | moment('DD/MM/YYYY') }}
          </div>
        </template>
        <template v-slot:item.observaciones="{ item }">
          <div v-if="item.obs_estado">
            {{ item.obs_estado }}
          </div>
          <div v-else-if="item.obs_chofer">
            {{ item.obs_chofer }}
          </div>
          <div v-else-if="item.observaciones">
            {{ item.observaciones }}
          </div>
        </template>
        <template v-slot:item.estado="{ item }">
          <div v-if="item.estado">
            {{ item.estado.nombre }}
          </div>
        </template>
        <template v-slot:item.chofer="{ item }">
          <div v-if="item.chofer">
            {{ item.chofer.nombre + ' ' + item.chofer.apellido }}
          </div>
          <div v-else>Sin asignar</div>
        </template>
        <template v-slot:item.autor="{ item }">
          <div v-if="item.autor">
            {{ item.autor.name }}
          </div>
        </template>
        <template v-slot:item.domicilio.cliente="{ item }">
          <div v-if="item.domicilio.cliente">
            {{ item.domicilio.cliente.razon_social }}
          </div>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="mdi-alert">
            No se ha encontrado ningún resultado para "{{ search }}".
          </v-alert>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="warning" icon="mdi-alert">
            No hay información de ordenes para mostrar.
          </v-alert>
        </template>
        <template v-slot:pageText="props">
          Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  data () {
    return {
      title: 'Ordenes',
      search: '',
      selected: [],
      published: null,
    }
  },
  created () {
    this.onEstadoInit()
    if (this.$route.params.id)
      this.setFiltersOrdenId(this.$route.params.id)
  },
  computed: {
    //
    ...mapGetters({
      collection: 'OrdenesHist/all',
      headers: 'OrdenesHist/headers',
      loading: 'OrdenesHist/loading',
      itemsLength: 'OrdenesHist/itemsLength',
      optionsGetter: 'OrdenesHist/options',
    }),
    options: {
      get () {
        return this.optionsGetter
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      onFetch: 'OrdenesHist/onFetch',
      onEstadoInit: 'Estados/onFetch',
    }),
    ...mapMutations({
      setOptions: 'OrdenesHist/setOptions',
      setPage: 'OrdenesHist/setPage',
      setFiltersOrdenId: 'OrdenesHist/setFiltersOrdenId'
    }),
    onBack () {
      this.$router.go(-1)
    }
  }
}
</script>
