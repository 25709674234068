<template>
  <div>
    <v-sheet>
      <v-container>
        <v-alert
          border="left"
          colored-border
          color="primary"
          icon="mdi-map-marker-check"
          elevation="2"
        >
          A continuación, se muestran los datos del domicilio seleccionado
          También puede optar por registrar un
          <v-btn
            color="primary"
            @click="onClearDomicilioEdited"
          >
            <v-icon left>mdi-keyboard-f3</v-icon>
            Domicilio nuevo
            <v-icon right>mdi-account-plus</v-icon>
          </v-btn>
        </v-alert>
        <v-alert
          v-if="!editedAndSelectedEquals"
          border="left"
          colored-border
          color="primary"
          icon="mdi-map-marker-check"
          elevation="2"
        >
          Para registrar las modificaciones en los datos del domicilio es necesario: 
          <v-btn
            color="primary"
            @click="onSubmit"
            :loading="loading"
          >
            <v-icon left>mdi-keyboard-f4</v-icon>
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"      
        >
          <v-row no-gutters>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="descripcion"
                placeholder="Descripcion"
                prepend-icon="mdi-text"
                :rules="rules.descripcion"
                label="Descripción"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="observaciones"
                placeholder="Observaciones"
                prepend-icon="mdi-text"
                :rules="rules.observaciones"
                label="Observaciones del domicilio"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <my-zona-select
                v-model="zona"
                :rules="rules.zona_id"
              ></my-zona-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="posicion"
                prepend-icon="mdi-format-list-numbered"
                :rules="rules.posicion"
                label="Posición"
                type="number"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="latitud && longitud">
              <div id="map"></div>
            </v-col>
            <v-col cols="12" md="6" v-if="!latitud || !longitud">
              <v-text-field
                v-model="latitud"
                placeholder="Ingrese una latitud"
                prepend-icon="mdi-text"
                label="Latitud"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" v-if="!latitud || !longitud">
              <v-text-field
                v-model="longitud"
                placeholder="Ingrese una longitud"
                prepend-icon="mdi-text"
                label="Longitud"
                v-bind="attrs"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                v-if="$can('domicilios.destroy')"
                color="error"
                @click="onEliminar"
              >
                Eliminar
                <v-icon right>mdi-delete</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-sheet>
    <my-domicilio-delete
      v-model="dialogDelete"
      :domicilio-id="id"
    ></my-domicilio-delete>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DomicilioDelete from '@/pages/domicilios/Delete'
import ZonaSelect from '@/pages/zonas/Select'
export default {
  components: {
    'my-domicilio-delete': DomicilioDelete,
    'my-zona-select': ZonaSelect
  },
  data () {
    return {
      valid: false,
      attrs: {
        color: 'primary',
        readonly: false,
      },
      dialogDelete: false,
      mapInstance: null,
    }
  },
  created () {
  //   this.onClearEdited()
  //   this.onClearSelected()
  },
  // mounted() {
  //   // Initialize Leaflet map
  //   const map = L.map('map').setView([51.505, -0.09], 13);
  //   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  //   }).addTo(map);
  // },,
  mounted() {
    this.onDibujarMapa()
  },
  beforeMount() {
    // Agrega el event listener en el gancho beforeMount
    window.addEventListener('keydown', this.handleKeyboard);
  },
  beforeDestroy() {
    // Remueve el event listener en el gancho beforeDestroy
    window.removeEventListener('keydown', this.handleKeyboard);
    this.onRemoveMap()
  },
  computed: {
    ...mapGetters('Domicilios', ['edited', 'rules', 'loading', 'editedAndSelectedEquals']),
    ...mapGetters({
      clienteEdited: 'Clientes/edited',
    }),
    id () {
      return this.edited.id
    },
    clienteNombre () {
      return this.clienteEdited.razon_social
    },
    descripcion: {
      get () {
        return this.edited.descripcion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          descripcion: value
        })
      }
    },
    latitud: {
      get () {
        return this.edited.latitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          latitud: value
        })
      }
    },
    longitud: {
      get () {
        return this.edited.longitud
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          longitud: value
        })
      }
    },
    observaciones: {
      get () {
        return this.edited.observaciones
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          observaciones: value
        })
      }
    },
    cliente: {
      get () {
        return this.edited.cliente_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          cliente_id: value
        })
      }
    },
    zona: {
      get () {
        return this.edited.zona_id
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          zona_id: value
        })
      }
    },
    posicion: {
      get () {
        return this.edited.posicion
      },
      set (value) {
        this.setEdited({
          ...this.edited,
          posicion: value
        })
      }
    },
  },
  watch: {
    edited (value) {
      this.onDibujarMapa()
    }
  }, 
  methods: {
    ...mapActions('Domicilios', ['onUpdate', 'onBeforeDelete', 'onDelete', 'onClearEdited', 'onClearSelected', 'onFetch']),
    ...mapMutations('Domicilios', ['setEdited', 'setFilters']),
    onSubmit (event) {
      event.preventDefault()
      this.$refs.form.validate()
      if (this.valid && !this.editedAndSelectedEquals) {
        this.onUpdate()
          .then(response => {
            this.onFetch()
          })
      }
    },
    onEliminar (event) {
      event.preventDefault()
      this.dialogDelete = true
    },
    onSubmitDelete (event) {
      event.preventDefault()
      this.onBeforeDelete()
        .then(response => {
          alert(response)
        })
    },
    onClearDomicilioEdited (event) {
      event.preventDefault()
      this.onClearSelected()
      this.onClearEdited()
      this.$refs.form.reset()
    },
    handleKeyboard(event) {
      if (!this.loading) {
        if (event.key === 'F3') {
          this.onClearDomicilioEdited(event)
        }
        else if (event.key === 'F4') {
          this.onSubmit(event)
        }
      }
    },
    onRemoveMap () {
      // Verifica si el contenedor ya tiene un mapa inicializado
      if (this.mapInstance) {
        // Si ya hay un mapa, destrúyelo antes de inicializar uno nuevo
        this.mapInstance.remove();
      }
    },
    onDibujarMapa (event) {
      this.onRemoveMap()
      const title = this.clienteNombre + ', ' + this.descripcion
                    + (!this.observaciones ? '' : '. ' + this.observaciones)
      if (this.latitud && this.longitud) {
        this.mapInstance = L.map('map').setView([this.latitud, this.longitud], 15);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.mapInstance);

        // Agrega un marcador
        L.marker([this.latitud, this.longitud]).addTo(this.mapInstance)
          .bindPopup(title)
          .openPopup();
      }
    }
  },
}
</script>
<style>
  #map {
    width: 100%;
    height: 400px; /* ajusta la altura según tus necesidades */
  }
</style>
