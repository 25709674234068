<template>
  <div>
    <v-sheet>
      <v-toolbar flat>
        <v-toolbar-title>Rendimiento de choferes</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Chofer
                </th>
                <th class="text-left">
                  Total venta diaria
                </th>
                <th class="text-left">
                  Cantidad Envases G.A.
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in desserts"
                :key="item.name"
              >
                <td>{{ item.name }}</td>
                <td>{{ item.calories }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row no-gutters>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Publicado desde el"
              v-model="publishedFrom"
            ></my-date-picker>
          </v-col>
          <v-col cols="12" md="4">
            <my-date-picker
              label="Publicado hasta el"
              v-model="publishedTo"
            ></my-date-picker>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import DatePicker from '@/components/DatePicker'
import ChoferSelect from '@/pages/choferes/Select'
export default {
  components: {
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker
  },
  data () {
    return {
      valid: false,
      submitButtonText: 'Guardar',
      cancelButtonText: 'Cancelar',
      desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
          },
          {
            name: 'Eclair',
            calories: 262,
          },
          {
            name: 'Cupcake',
            calories: 305,
          },
          {
            name: 'Gingerbread',
            calories: 356,
          },
          {
            name: 'Jelly bean',
            calories: 375,
          },
          {
            name: 'Lollipop',
            calories: 392,
          },
          {
            name: 'Honeycomb',
            calories: 408,
          },
          {
            name: 'Donut',
            calories: 452,
          },
          {
            name: 'KitKat',
            calories: 518,
          },
        ],
    }
  },
  filters: {
    percent: (dec) => {
      console.log(_.floor(dec, 2))
      return _.floor(_.multiply(dec, 100), 0) + '%'
    }
  },
  created () {
    this.publishedFrom = this.$moment().subtract(30, 'days').format('YYYY-MM-DD')
    this.publishedTo = this.$moment().format('YYYY-MM-DD')
  },
  mounted () {
    // this.onDefaults()
    // if (this.$route.params.id)
    //   this.onFind(this.$route.params.id)
    // this.onChoferes()
  },
  computed: {
    ...mapGetters({
      filters: 'Estadisticas/filters',
      labels: 'Estadisticas/labels',
      series: 'Estadisticas/series',
      loading: 'Estadisticas/loading',
      resultados: 'Estadisticas/resultados',
    }),
    dates () {
      return [
        this.publishedFrom, this.publishedTo
      ]
    },
    chartOptions () {
      return {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          }
        },
        dataLabels: {
          enabled: true,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: this.labels,
        },
      }
    },
    chofer: {
      get () {
        return this.filters.chofer_id
      },
      set (value) {
        this.setFiltersChoferId(value)
        // this.onChoferes()
        this.onSubmit()
      }
    },
    publishedFrom: {
      get () {
        return this.filters.published.from
      },
      set (value) {
        this.setFiltersPublishedFrom(value)
        // this.onChoferes()
        this.onSubmit()
      }
    },
    publishedTo: {
      get () {
        return this.filters.published.to
      },
      set (value) {
        this.setFiltersPublishedTo(value)
        this.onSubmit()
      }
    },
  },
  methods: {
    ...mapActions({
      onChoferes: 'Estadisticas/onChoferes',
    }),
    ...mapMutations({
      setFiltersChoferId: 'Estadisticas/setFiltersChoferId',
      setFiltersPublishedFrom: 'Estadisticas/setFiltersPublishedFrom',
      setFiltersPublishedTo: 'Estadisticas/setFiltersPublishedTo',
    }),
    onCancel () {
      this.$router.go(-1)
    },
    onSubmit () {
      if (this.publishedTo && this.publishedFrom && this.chofer)
        this.onChoferes()
    },
  },
}
</script>