<template>
  <div>
    <v-dialog
      v-model="dialogLocal"
      persistent
      width="500px"
      transition="dialog-transition"
    >
      
      <v-sheet>
        <v-container>
          <v-toolbar flat>
            <v-toolbar-title>Eliminar cliente</v-toolbar-title>
          </v-toolbar>
          <v-alert
            v-if="registrosRelacionados"
            border="left"
            colored-border
            color="warning"
            icon="mdi-alert"
            elevation="2"
          >
            También se procede a eliminar toda la información relacionada al cliente
          </v-alert>
          <v-list v-if="registrosRelacionados">
            <v-subheader>Información relacionada</v-subheader>
            <v-list-item
              v-if="domicilios > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Domicilios</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ domicilios }} asociado{{ domicilios > 0 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="pendientes > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-table-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Ordenes Pendientes</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ pendientes }} asociada{{ pendientes > 1 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="finalizadas > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-table-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Ordenes Finalizadas</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ finalizadas }} asociada{{ finalizadas > 1 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="postergadas > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-table-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Ordenes Postergadas</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ postergadas }} asociada{{ postergadas > 1 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="canceladas > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-table-star</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Ordenes Canceladas</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ canceladas }} asociada{{ canceladas > 1 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="tanques > 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-storage-tank</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-subtitle>Tanques</v-list-item-subtitle>
                <v-list-item-title class="text-wrap">Tiene {{ tanques }} asociado{{ tanques > 0 ? 's' : '' }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>mdi-alert</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
            <v-btn
              @click="onCancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="error"
              @click="onSubmit"
              :loading="loading"
            >
              Confirmar
            </v-btn>
        </v-container>
      </v-sheet>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
export default {
  components: {
    //
  },
  model: {
    prop: 'dialog',
    event: 'change'
  },
  props: {
    dialog: {
      type: Boolean,
      default: true
    },
    clienteId: {
      type: Number,
      default: null,
    }
  },
  data () {
    return {
      title: '¿Está seguro de borrar este registro?',
    }
  },
  created () {
    // this.onDefaults()
    // if (this.$route.params.id)
    //   this.onFind(this.$route.params.id)
  },
  computed: {
    ...mapGetters('Clientes', ['edited', 'loading', 'relaciones']),
    dialogLocal: {
      get () {
        return this.dialog
      },
      set (value) {
        this.$emit('change', value)
      }
    },
    registrosRelacionados () {
      return this.pendientes > 0 ||
        this.finalizadas > 0 ||
        this.postergadas > 0 ||
        this.canceladas > 0 || 
        this.tanques > 0 || 
        this.domicilios > 0
    },
    pendientes () {
      return this.relaciones.ordenes.pendientes > 0 ? this.relaciones.ordenes.pendientes : 0
    },
    finalizadas () {
      return this.relaciones.ordenes.finalizadas > 0 ? this.relaciones.ordenes.finalizadas : 0
    },
    postergadas () {
      return this.relaciones.ordenes.postergadas > 0 ? this.relaciones.ordenes.postergadas : 0
    },
    canceladas () {
      return this.relaciones.ordenes.canceladas > 0 ? this.relaciones.ordenes.canceladas : 0
    },
    tanques () {
      return this.relaciones.tanques > 0 ? this.relaciones.tanques : 0
    },
    domicilios () {
      return this.relaciones.domicilios > 0 ? this.relaciones.domicilios : 0
    },
  },
  watch: {
    dialogLocal (value) {
      if (value) {
        this.onBeforeDelete().then(response => {
        })
      }
    }
  },
  methods: {
    ...mapActions('Clientes', ['onDelete', 'onBeforeDelete', 'onFind', 'onClearEdited']),
    onCancel (event) {
      event.preventDefault()
      this.dialogLocal = false
    },
    onSubmit (event) {
      event.preventDefault()
      this.onDelete().then(response => {
        this.dialogLocal = !this.dialogLocal
        this.$router.go(-1)
        // this.$router.push({ name: 'ordenes-datatable' })
      })
    },
  },
}
</script>
