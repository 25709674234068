<template>
  <div v-if="list">
    <v-list flat>
      <v-subheader>Seleccione un chofer</v-subheader>
      <v-progress-linear
        v-show="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-list-item-group
        v-model="selectedLocal"
      >
        <template v-for="(item, i) in all">
          <v-divider
            v-if="!item"
            :key="`divider-${i}`"
          ></v-divider>

          <v-list-item
            v-else
            :key="`item-${i}`"
            :value="item.id"
            active-class="blue--text"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title class="text-wrap" v-text="item.nombre + ' ' + item.apellido"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  color="blue accent-4"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
      <v-list-item
        link
        :class="sinAsignar ? 'blue--text' : ''"
        @click="onSinAsignar"
      >
        <v-list-item-content>
          <v-list-item-title class="text-wrap">Sin asignar</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-checkbox
            :input-value="sinAsignar"
            color="blue accent-4"
          ></v-checkbox>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
  <div v-else>
    <v-select
      v-model="selectedLocal"
      :items="all"
      hide-no-data
      :item-text="item => item.nombre + ' ' + item.apellido"
      item-value="id"
      class="text-wrap"
      :rules="rules"
      prepend-icon="mdi-truck"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    ></v-select>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },

  props: {
    selected: Number,
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    first: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    list: {
      type: Boolean,
      default: false
    },
    excepto: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      placeholder: 'Seleccione un chofer',
      label: 'Chofer',
      sinAsignar: false,
    }
  },

  created () {
    this.onFetch().then(response => {
      if (this.all.length > 0 && this.first) {
        this.selectedLocal = this.all[0]['id']
      }
    })
  },

  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },
      set (value) {
        if (value && this.sinAsignar) this.sinAsignar = false
        this.$emit('change', value)
      }
    },
    ...mapGetters('Choferes', ['all', 'loading']),
  },

  methods: {
    ...mapActions({
      onFetch: 'Choferes/onSelectable',
    }),
    onSelect (value) {
      this.$emit('change', value.id)
    },
    onSinAsignar() {
      this.sinAsignar = !this.sinAsignar
      this.selectedLocal = null
      this.$emit('sinasignar', this.sinAsignar)
    }
  },
}
</script>
