<template>
  <div>
    <!--
    -->
    <v-menu
      offset-x
      absolute
      v-model="showMenu"
      style="max-width: 600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-icon dark left>mdi-store-marker</v-icon>
          {{ selected && selected.abreviatura ? selected.abreviatura : '---'}}
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, index) in all"
          :key="index"
          @click="onSelect(item)"
        >
          <v-list-item-icon>
            <v-icon>{{ selected.id === item.id ? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.abreviatura }} - {{ item.nombre }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!--
    <v-select
      v-model="selectedLocal"
      :items="all"
      hide-no-data
      item-text="nombre"
      item-value="id"
      :rules="rules"
      prepend-icon="mdi-propane-tank"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :readonly="readonly"
      :clearable="clearable"
    >
      <template v-slot:item="{ item, on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
        >
          <v-list-item-content>
            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
    -->
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  data () {
    return {
      showMenu: false,
    }
  },
  created () {
    this.onFetch()
  },
  computed: {
    selectedLocal: {
      get () {
        return this.selected
      },

      set (value) {
        this.setSelected(value)
      }
    },
    ...mapGetters('Sucursales', ['all', 'selected'])
  },

  watch: {
    selectedLocal (value) {
      if (value && value.id) this.$emit('sucursal_id_selected', value.id);
    }
  },
  methods: {
    ...mapActions('Sucursales', ['onFetch']),
    ...mapMutations('Sucursales', ['setSelected']),
    onChange (value) {
      // this.selectedLocal = this.all.value
    },
    onSelect (item) {
      this.selectedLocal = item
    }
  }
}
</script>
