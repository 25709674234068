const URL = '/sucursales';

const state = {
  all: [],
  selected: {
    id: null,
    nombre: null,
    abreviatura: null,
  },
  default: {
    id: null,
    nombre: null,
    abreviatura: null,
  },
  loading: false
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  selected (state) {
    return state.selected
  },
  loading (state) {
    return state.loading
  }
}

// ACTIONS
const actions = {
  onFetch ({ commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL)
        .then(response => {
          commit('setAll', response.data)
          if (response.data && response.data.length > 0 && !state.selected.id)
            commit('setSelected', response.data[0])
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  }
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setSelected (state, payload) {
    state.selected = payload
  },
  setLoading (state, payload) {
    state.loading = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
