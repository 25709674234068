<template>
  <div>
    <v-sheet>
      <v-subheader>Buscar cliente</v-subheader>
      <v-toolbar flat>
        <v-text-field
          v-model="searchText"
          prepend-icon="mdi-magnify"
          placeholder="Escriba apellido, nombre, documento, domicilio, observaciones"
          messages="Enter para buscar"
          @keyup="onSubmit"
          :loading="loading"
        >
          <template v-slot:prepend>
            <v-btn
              icon
              @click="onSubmit"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-text-field>
        </v-btn>
      </v-toolbar>
    </v-sheet>
    <v-data-table
      v-if="all && all.length > 0"
      :headers="headers"
      :items="all"
      :footer-props="{
        itemsPerPageText: 'Filas por página'
      }"
      :loading="loading"
      :options.sync="optionsLocal"
      :server-items-length="itemsLength"
      @click:row="onSelect"
    >
      <template v-slot:item.cliente_id="{ item }">
        <v-btn
          :to="{ name: 'clientes-form', params: { id: item.cliente_id } }"
          icon
        >
          <v-icon>mdi-account-edit</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-results>
        <v-alert
          :value="true"
          color="error"
          icon="mdi-alert"
        >
          No se ha encontrado ningún resultado para "{{ searchText }}".
        </v-alert>
      </template>
      <template v-slot:no-data>
        <v-alert
          v-if="filters.searchText"
          :value="true"
          color="warning"
          icon="mdi-alert"
        >
          No hay clientes para mostrar que coincidan con "{{ searchText }}".
        </v-alert>
        <v-alert
          v-else
          :value="true"
          dark
          color="primary"
          icon="mdi-info"
        >
          No se ha cargado información de clientes.
        </v-alert>
      </template>
      <template v-slot:pageText="props">
        Filas {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
export default {
  model: {
    prop: 'selected',
    event: 'change'
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    rules: Array,
    color: String,
  },
  data () {
    return {
      valid: false,
      searchButtonText: 'Buscar',
      label: 'Seleccione un cliente',
      placeholder: 'Busque por Número ó razón social',
      attrs: {
        class: 'text-wrap',
      },
    }
  },
  computed: {
    ...mapGetters('DomiciliosFindOrCreate', ['all', 'filters', 'headers', 'loading', 'itemsLength', 'options']),
    ...mapState('DomiciliosFindOrCreate', ['errors']),
    searchText: {
      get () {
        return this.filters.searchText
      },
      set (value) {
        this.setFilters({
          ...this.filters,
          searchText: value,
          inactivos: null
        })
      }
    },
    optionsLocal: {
      get () {
        return this.options
      },
      set (value) {
        this.setOptions(value)
        this.onFetch()
      }
    },
  },
  methods: {
    ...mapActions('DomiciliosFindOrCreate', ['onFetch']),
    ...mapMutations('DomiciliosFindOrCreate', ['setAll', 'setOptions', 'setEdited', 'setSelected', 'setFilters', 'setErrors']),
    onSelect (value) {
      const aux = {
        id: value.id,
        cliente: value.cliente.razon_social,
        descripcion: value.descripcion,
        observaciones: value.observaciones,
        cliente_id: value.cliente_id,
        zona_id: value.zona_id
      }
      this.setEdited(aux)
      this.setSelected(aux)

      this.setFilters({
        ...this.filters,
        searchText: null,
        inactivos: null
      })

      this.setErrors({
        ...this.errors,
        duplicado: false
      })

      this.setAll([])
      this.$emit('change', value.id)
      this.$emit('selected', value)
    },
    onSubmit (event) {
      if (event.key === 'Enter') this.onFetch()
      event.preventDefault()
    },
  }
}
</script>