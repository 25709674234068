<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('Sucursales', ['selected']),
  },
  watch: {
    selected (value) {
      if (value && value.id > 0) {
        // Se agrega la función authInterceptor para las solicitudes HTTP
        window.axios.defaults.headers.common['X-Sucursal-Id'] = value.id;
        console.log('Sucursal cambio!')
        this.setLoadingOrdenes(true);
        this.setLoadingChoferes(true);
        this.setLoadingEstados(true);
        this.setLoadingZonas(true);
        this.onZonas()
          .finally(() => {
            this.onChoferes()
              .finally(() => {
                this.onOrdenes()
              })
            //
          })
        // this.onOrdenes()
        // this.onChoferes()
        // this.onZonas()
      }
    }
  },
  methods: {
    ...mapActions({
      onOrdenes: 'Ordenes/onFetch',
      onChoferes: 'Choferes/onSelectable',
      onEstados: 'Estados/onFetch',
      onZonas: 'Zonas/onFetchAll'
    }),
    ...mapMutations({
      setLoadingOrdenes: 'Ordenes/setLoading',
      setLoadingChoferes: 'Choferes/setLoading',
      setLoadingEstados: 'Estados/setLoading',
      setLoadingZonas: 'Zonas/setLoading',
    })
  },
}
</script>
