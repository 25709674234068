<template>
  <div>
    
    <my-navigation-drawer
    ></my-navigation-drawer>
    <my-filters-panel
    ></my-filters-panel>
    <v-divider></v-divider>
    <my-clientes-datatable></my-clientes-datatable>
    <!-- Evntos del componente sucursal -->
    <my-eventbus></my-eventbus>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EventBus from '@/pages/clientes/EventBus'
import Datatable from '@/pages/clientes/Datatable'
import Navigation from '@/pages/clientes/Navigation'
import Filters from '@/pages/clientes/Filters'
export default {
  components: {
    'my-eventbus': EventBus,
    'my-clientes-datatable': Datatable,
    'my-navigation-drawer': Navigation,
    'my-filters-panel': Filters
  },
  data () {
    return {
      //
    }
  },
  created () {
    // this.onFetch()
  },
  computed: {
    ...mapGetters('Clientes', ['loading']),
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', ['setOptions', 'setSelected', 'setFilters']),
    onSelect (value) {
      const selected = {
        id: value.id,
        descripcion: value.descripcion,
        observaciones: value.observaciones,
        latitud: value.latitud,
        longitud: value.longitud,
        cliente_id: value.cliente_id,
        posicion: value.posicion,
        zona_id: value.zona_id,
      }
      this.setEdited(selected)
      this.setSelected(selected)
    },
  }
}
</script>
