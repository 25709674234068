<template>
  <div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  data () {
    return {
      //
    }
  },
  computed: {
    ...mapGetters('Sucursales', ['selected', 'loading']),
  },
  watch: {
    selected (value) {
      if (value && value.id > 0) {
        window.axios.defaults.headers.common['X-Sucursal-Id'] = value.id;
        this.setLoading(true)
        this.onFetch()
      }
    }
  },
  methods: {
    ...mapActions('Clientes', ['onFetch']),
    ...mapMutations('Clientes', ['setLoading']),
  },
}
</script>
