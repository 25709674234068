const URL = '/ordenes';

const state = {
  all: [],
  found: [],
  selected: {},
  edited: {
    id: null,
    advertencia: null,
    chofer_id: null,
    cliente: null,
    domicilio: null,
    domicilio_id: null,
    estado_id: null,
    observaciones: null,
    published_at: null,
    zona_id: null,
  },
  sort: {
    by: 'created_at',
    desc: 1,
    list: [
      {
        key: 'Fecha creado',
        value: 'created_at',
      },
      {
        key: 'Actualizaciones',
        value: 'updated_at',
      },
      {
        key: 'Fecha publicado',
        value: 'published_at',
      },
    ],
  },
  filters: {
    cliente: null,
    domicilio: null,
    estado_id: null,
    chofer_id: null,
    sinAsignar: null,
    zona_id: null,
    created: {
      from: null,
      to: null,
    },
    published: {
      from: null,
      to: null,
    },
  },
  // sort: {
  //   collection: [
  //     'Orden',
  //     'Cliente',
  //     'Zona',
  //     'Estado'
  //   ],
  //   by: null,
  //   desc: false,
  // },
  loading: false,
  headers: [
    {
      text: 'Editar',
      value: 'updated_at',
      align: 'center'
    },
    {
      text: 'Publicado',
      value: 'published_at',
      align: 'center'
    },
    {
      text: 'Cliente',
      value: 'domicilio.cliente',
      align: 'center'
    },
    {
      text: 'Chofer',
      value: 'chofer',
      align: 'center'
    },
  ],
  validations: {
    cliente: [
      // v => !!v || 'Este campo es necesario',
    ],
    chofer_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    domicilio: [
      // v => !!v || 'Este campo es necesario',
    ],
    domicilio_id: [
      v => !!v || 'Este campo es necesario',
    ],
    published_at: [
      // v => !!v || 'Este campo es necesario',
    ],
    estado_id: [
      // v => !!v || 'Este campo es necesario',
    ],
    advertencia: [
      // v => !!v || 'Este campo es necesario',
    ],
    zona_id: [
      // v => !!v || 'Este campo es necesario',
    ],
  },
  defaults: {
    id: null,
    chofer_id: null,
    cliente: null,
    domicilio: null,
    domicilio_id: null,
    estado_id: null,
    advertencia: null,
    published_at: null,
    zona_id: null,
  },
  options: {},
  itemsLength: null,
}

// GETTERS
const getters = {
  all (state) {
    return state.all
  },
  anySelectedFilters (state) {
    return state.filters.zona_id || 
          state.filters.chofer_id ||
          state.filters.estado_id ||
          state.filters.domicilio ||
          state.filters.cliente ||
          state.filters.created.from ||
          state.filters.created.to ? false : true;
  },
  filters (state) {
    return state.filters
  },
  sort (state) {
    return state.sort
  },
  sortList (state) {
    return state.sort.list
  },
  edited (state) {
    return state.edited
  },
  selected (state) {
    return state.selected
  },
  rules (state) {
    return state.validations
  },
  headers (state) {
    return state.headers
  },
  itemsLength (state) {
    return state.itemsLength
  },
  options (state) {
    return state.options
  },
  defaults (state) {
    return state.defaults
  },
  loading (state) {
    return state.loading
  },
}

// ACTIONS
const actions = {
  onFetch ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.get(URL, {
          params: {
            cliente: state.filters.cliente,
            domicilio: state.filters.domicilio,
            chofer_id: state.filters.chofer_id,
            created_from: state.filters.created.from,
            created_to: state.filters.created.to,
            estado_id: 1,
            published_from: state.filters.published.from,
            published_to: state.filters.published.to,
            sinAsignar: state.filters.sinAsignar,
            zona_id: state.filters.zona_id,
            itemsPerPage: state.options.itemsPerPage,
            page: state.options.page,
            sortBy: state.sort.by,
            sortDesc: state.sort.desc,
          }
        })
        .then(response => {
          commit('setAll', response.data.data)
          commit('setItemsLength', response.data.meta.total)
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDefaults ({ state, commit }) {
    commit('setEdited', state.defaults)
  },
  onSave ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado la orden de distribucion', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar la orden de distribucion', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdateEstado ({ state, commit }, orden_id, estado_id) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.post(URL + '/estados/' + orden_id, {
          estado_id: estado_id
        })
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado el estado de la orden', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar el estado de la orden', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onUpdate ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.put(URL + '/' + state.edited.id, state.edited)
        .then(response => {
          commit('setId', response.data.id)
          commit('ResponseHTTP/addSuccess', 'Se ha guardado la orden de distribucion', { root: true })
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al guardar la orden de distribucion', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
  onDelete ({ state, commit }) {
    return new Promise((resolve, reject) => {
      commit('setLoading', true)
      window.axios.delete(URL + '/' + state.edited.id)
        .then(response => {
          commit('ResponseHTTP/addSuccess', 'Se ha borrado la orden de distribucion', { root: true })
          commit('setEdited', state.defaults)
          resolve(response.data)
        })
        .catch(error => {
          commit('ResponseHTTP/addError', 'Error al borrar la orden de distribucion', { root: true })
          reject(error)
        })
        .finally(() => {
          commit('setLoading', false)
        })
    })
  },
}

// MUTATIONS
const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  setFound (state, payload) {
    state.found = payload
  },
  setEdited (state, payload) {
    if (state.all) {
      // state.all.push(Object.assign({}, payload))
    }
    state.edited = Object.assign({}, payload)
  },
  setSelected (state, payload) {
    state.selected = Object.assign({}, payload)
  },
  setLoading (state, payload) {
    state.loading = payload
  },
  setId (state, payload) {
    state.edited.id = payload
  },
  setDomicilioId (state, payload) {
    state.edited.domicilio_id = payload
  },
  setDomicilio (state, payload) {
    state.edited.domicilio = payload
  },
  setEstadoId (state, payload) {
    state.edited.estado_id = payload
  },
  setChoferId (state, payload) {
    state.edited.chofer_id = payload
  },
  setCliente (state, payload) {
    state.edited.cliente = payload
  },
  setPublishedAt (state, payload) {
    state.edited.published_at = payload
  },
  setAdvertencia (state, payload) {
    state.edited.advertencia = payload
  },
  setObservaciones (state, payload) {
    state.edited.observaciones = payload
  },
  setObsEstado (state, payload) {
    state.edited.obs_estado = payload
  },
  setObsChofer (state, payload) {
    state.edited.obs_chofer = payload
  },
  setZonaId (state, payload) {
    state.edited.zona_id = payload
  },
  setPage (state, payload) {
    state.options.page = payload
  },
  setItemsPerPage (state, payload) {
    state.options.itemsPerPage = payload
  },
  setItemsLength (state, payload) {
    state.itemsLength = payload
  },
  setOptions (state, payload) {
    state.options = payload
  },
  setFiltersCliente (state, payload) {
    window.sessionStorage.setItem('ordenes.cliente', payload)
    state.filters.cliente = payload
  },
  setFiltersDomicilio (state, payload) {
    state.filters.domicilio = payload
  },
  setFiltersEstadoId (state, payload) {
    window.sessionStorage.setItem('ordenes.estado_id', payload)
    state.filters.estado_id = payload
  },
  setFiltersChoferId (state, payload) {
    window.sessionStorage.setItem('ordenes.chofer_id', payload)
    state.filters.chofer_id = payload
  },
  setFiltersZonaId (state, payload) {
    window.sessionStorage.setItem('ordenes.zona_id', payload)
    state.filters.zona_id = payload
  },
  setFiltersCreatedFrom (state, payload) {
    window.sessionStorage.setItem('ordenes.created.to', payload)
    state.filters.created.from = payload
  },
  setFiltersCreatedTo (state, payload) {
    window.sessionStorage.setItem('ordenes.created.from', payload)
    state.filters.created.to = payload
  },
  setFiltersPublishedFrom (state, payload) {
    window.sessionStorage.setItem('ordenes.published.from', payload)
    state.filters.published.from = payload
  },
  setFiltersPublishedTo (state, payload) {
    window.sessionStorage.setItem('ordenes.published.to', payload)
    state.filters.published.to = payload
  },
  setFiltersSinAsignar (state, payload) {
    window.sessionStorage.setItem('ordenes.sin_asignar', payload)
    state.filters.sinAsignar = payload
  },
  setSortBy (state, payload) {
    state.sort.by = payload
  },
  setSortDesc (state, payload) {
    state.sort.desc = payload
  },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
