const state = {
  all: [],
  recent: null,
}

const getters = {
  all (state) {
    return state.all
  },
  recent (state) {
    return state.recent
  },
}

const actions = {
  //
}

const mutations = {
  setAll (state, payload) {
    state.all = payload.slice()
  },
  addSuccess (state, payload) {
    state.recent = {
      title: payload,
      details: '',
      color: 'success',
      type: 'success',
      created_at: new Date()
    }
    state.all.push(state.recent)
  },
  addError (state, payload) {
    state.recent = {
      title: payload,
      details: '',
      color: 'error',
      type: 'error',
      created_at: new Date()
    }
    state.all.push(state.recent)
  },
  viewed (state) {
    state.recent = null
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}