<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          @click.prevent="onSelect(orden)"
          outlined
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            v-if="orden.advertencia"
            small
            left
          >
            mdi-alert
          </v-icon>
          {{ orden.advertencia || '...' }}
        </v-chip>
      </template>

      <v-card>
        <v-toolbar flat>
          <v-btn
            text
            @click.prevent="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cambiar advertencia</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="onSubmit"
          >
            <v-icon left>mdi-check</v-icon>
            Guardar
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-if="cliente"
                :value="cliente.razon_social ? cliente.razon_social : cliente.nombre + ' ' + cliente.apellido"
                label="Cliente"
                prepend-icon="mdi-account"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="advertencia"
                messages="Enter para guardar"
                @keydown.enter="onSubmit"
                label="Advertencia"
                prepend-icon="mdi-alert"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  props: {
    orden: {
      type: Object,
      default: null
    },
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      placeholder: 'Seleccione un estado',
      dialog: false,
      cliente: false,
      domicilio: false,
      menu: false,
    }
  },

  created () {
    //
  },

  mounted () {
    //
  },

  computed: {
    ...mapGetters({
      edited: 'Ordenes/edited',
    }),
    readonlyLocal () {
      return this.$can('menu.ordenes.publicado')
      // return this.readonly || this.$can('menu.ordenes.publicado')
    },
    advertencia: {
      get () {
        return this.edited.advertencia
      },

      set (value) {
        this.setAdvertencia(value)
      }
    },
  },

  watch: {
    readonly (val) {
      this.menu = false
    }
  },

  methods: {
    ...mapActions({
      onSave: 'Ordenes/onUpdate',
    }),
    ...mapMutations({
      setId: 'Ordenes/setId',
      setAdvertencia: 'Ordenes/setAdvertencia',
      setObsEstado: 'Ordenes/setObsEstado',
      setObsChofer: 'Ordenes/setObsChofer',
      setEstadoId: 'Ordenes/setEstadoId',
      setEdited: 'Ordenes/setEdited',
      setChoferId: 'Ordenes/setChoferId',
      setDomicilioId: 'Ordenes/setDomicilioId',
      setPublishedAt: 'Ordenes/setPublishedAt'
    }),
    onSelect (value) {
      this.setId(value.id)
      this.setAdvertencia(value.advertencia)
      this.setObsEstado(value.obs_estado)
      this.setObsChofer(value.obs_chofer)
      this.setEstadoId(value.estado_id)
      this.setChoferId(value.chofer_id)
      this.setDomicilioId(value.domicilio_id)
      this.setPublishedAt(value.published_at)
      this.cliente = value.domicilio.cliente
      this.domicilio = value.domicilio
    },
    onSubmit () {
      this.onSave()
        .then(response => {
          this.dialog = false
          this.$emit('refresh', true)
        })
    }
  },
}
</script>
