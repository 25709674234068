<template>
  <div>
    <v-sheet>
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-container>
          <v-toolbar flat>
            <v-btn
              text
              @click="onCancel"
            >
              <v-icon left>mdi-chevron-left</v-icon>
              {{ cancelButtonText }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="onSubmit"
              :loading="loading"
            >
              <v-icon :left="$vuetify.breakpoint.smAndUp">mdi-check</v-icon>
              <div class="hidden-xs-only">{{ submitButtonText }}</div>
            </v-btn>
          </v-toolbar>
          <v-row>
            <v-col cols="12" md="4">
              <my-chofer-select
                v-model="chofer"
                :rules="rules.chofer_id"
              ></my-chofer-select>
            </v-col>
            <v-col cols="12" md="4">
              <my-envase-select
                v-model="envase"
                :rules="rules.envase_id"
              ></my-envase-select>
            </v-col>
            <v-col cols="12" md="4">
              <my-date-picker
                v-model="salidasFecha"
                :rules="rules.salidas_fecha"
                label="Fecha de salida"
              ></my-date-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="salidasCantidadPropios"
                placeholder="Envases egresados de la planta"
                label="Salidas"
                prepend-icon="mdi-counter"
                color="primary"
                :rules="rules.salidas_cantidad_propios"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ventasCantidadPropios"
                placeholder="Envases ingresados de Sartini Gas"
                label="Ventas"
                prepend-icon="mdi-counter"
                color="primary"
                :rules="rules.ventas_cantidad_propios"
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="ventasCantidadCompetencia"
                placeholder="Envases ingresados de la Competencia"
                label="En G.A."
                prepend-icon="mdi-counter"
                color="primary"
                :rules="rules.ventas_cantidad_competencia"
                clearable
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import EnvaseSelect from '@/pages/envases/Select'
import ChoferSelect from '@/pages/choferes/Select'
import DatePicker from '@/components/DatePicker'
export default {
  components: {
    'my-envase-select': EnvaseSelect,
    'my-chofer-select': ChoferSelect,
    'my-date-picker': DatePicker,
  },
  data () {
    return {
      valid: true,
      submitButtonText: 'Guardar',
      cancelButtonText: 'Volver',
    }
  },
  created () {
    if (!this.edited.chofer_id) this.setChoferId(null)
    if (!this.edited.envase_id) this.setEnvaseId(null)
    this.setId(null)
    this.setSalidasFecha(null)
    this.setSalidasCantidadPropios(null)
    this.setVentasFecha(null)
    this.setVentasCantidadPropios(null)
    this.setVentasCantidadCompetencia(null)
  },
  computed: {
    ...mapGetters({
      edited: 'Movimientos/edited',
      rules: 'Movimientos/rules',
      loading: 'Movimientos/loading',
    }),

    salidasFecha: {
      get () {
        return this.edited.salidas_fecha
      },

      set (value) {
        this.setSalidasFecha(value)
      }
    },
    salidasCantidadPropios: {
      get () {
        return this.edited.salidas_cantidad_propios
      },

      set (value) {
        this.setSalidasCantidadPropios(value)
      }
    },
    ventasFecha: {
      get () {
        return this.edited.ventas_fecha
      },

      set (value) {
        this.setVentasFecha(value)
      }
    },
    ventasCantidadPropios: {
      get () {
        return this.edited.ventas_cantidad_propios
      },

      set (value) {
        this.setVentasCantidadPropios(value)
      }
    },
    ventasCantidadCompetencia: {
      get () {
        return this.edited.ventas_cantidad_competencia
      },

      set (value) {
        this.setVentasCantidadCompetencia(value)
      }
    },
    chofer: {
      get () {
        return this.edited.chofer_id
      },
      set (value) {
        this.setChoferId(value)
      }
    },
    envase: {
      get () {
        return this.edited.envase_id
      },
      set (value) {
        this.setEnvaseId(value)
      }
    },
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      onSave: 'Movimientos/onSave',
      onDefaults: 'Movimientos/onDefaults',
    }),
    ...mapMutations({
      setEdited: 'Movimientos/setEdited',
      setId: 'Movimientos/setId',
      setSalidasFecha: 'Movimientos/setSalidasFecha',
      setSalidasCantidadPropios: 'Movimientos/setSalidasCantidadPropios',
      setVentasFecha: 'Movimientos/setVentasFecha',
      setVentasCantidadPropios: 'Movimientos/setVentasCantidadPropios',
      setVentasCantidadCompetencia: 'Movimientos/setVentasCantidadCompetencia',
      setChoferId: 'Movimientos/setChoferId',
      setEnvaseId: 'Movimientos/setEnvaseId',
    }),
    onCancel () {
      this.resetEdited()
      this.$refs.form.resetValidation()
      this.$router.go(-1)
    },
    onReady (value) {
      this.onSave().then(response => {
        this.resetEdited()
      })
    },
    onSubmit () {
      this.$refs.form.validate()
      if (this.valid) {
        this.onSave().then(() => {
          this.resetEdited()
          this.$refs.form.resetValidation()
        })
      }
    },
    resetEdited () {
      this.setChoferId(null)
      this.setEnvaseId(null)
      this.setId(null)
      this.setSalidasFecha(null)
      this.setSalidasCantidadPropios(null)
      this.setVentasFecha(null)
      this.setVentasCantidadPropios(null)
      this.setVentasCantidadCompetencia(null)
    },
    onSelected (value) {
    }
  },
}
</script>
