<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <div v-if="chip">
          <v-chip
            v-if="vencido"
            v-bind="attrs"
            v-on="on"
            color="red darken-1"
            @click.prevent="onSelect(orden)"
            dark
          >
            <v-icon
              left
              small
              v-text="orden.estado.icono"
            ></v-icon>
            {{ orden.estado.nombre }}
          </v-chip>
          <v-chip
            v-else
            v-bind="attrs"
            v-on="on"
            :color="orden.estado.color"
            @click.prevent="onSelect(orden)"
            dark
          >
            <v-icon
              left
              small
              v-text="orden.estado.icono"
            ></v-icon>
            {{ orden.estado.nombre }}
          </v-chip>
        </div>
        <div v-else>
          <v-icon
            v-bind="attrs"
            v-on="on"
            @click.prevent="onSelect(orden)"
            size="30"
            color
            dark
            :style="'color:'+ orden.estado.color"
            v-text="orden.estado.icono"
          ></v-icon>
        </div>
      </template>

      <v-card>
        <v-toolbar
          flat
        >
          <v-btn
            text
            @click="dialog = false"
            :loading="loading"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Cambiar estado</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="onSubmit"
            :loading="loading"
            v-if="$can('menu.ordenes.estado')"
          >
            <v-icon :left="$vuetify.breakpoint.smAndUp" dark>mdi-check</v-icon>
            <div class="hidden-xs-only">Guardar</div>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-if="cliente"
                :value="cliente.razon_social ? cliente.razon_social : cliente.nombre + ' ' + cliente.apellido"
                label="Cliente"
                prepend-icon="mdi-account"
                color="primary"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="selected"
                :items="collection"
                hide-no-data
                item-text="nombre"
                item-value="id"
                :rules="rules"
                :label="label"
                :placeholder="placeholder"
                :prepend-icon="!hideIcon ? 'mdi-list-status' : ''"
                color="primary"
                :readonly="!$can('menu.ordenes.estado')"
                :clearable="clearable"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                    dense
                  >
                    <v-list-item-icon>
                      <v-icon v-html="item.icono"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    :color="item.color"
                    dark
                    :key="index"
                  >
                    <v-icon left>{{ item.icono }}</v-icon>
                    <span>{{ item.nombre }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EstadoSelect from '@/pages/estados/Select'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {
    'my-estado-select': EstadoSelect
  },
  props: {
    orden: {
      type: Object,
      default: null
    },
    rules: Array,
    readonly: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    chip: {
      type: Boolean,
      default: false
    },
    descripcion: {
      type: String,
      default: ''
    },
    icono: {
      type: String,
      default: 'mdi-plus'
    },
    color: {
      type: String,
      default: 'primary'
    },
    hideIcon: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      placeholder: 'Seleccione un estado',
      label: 'Estado',
      dialog: false,
      cliente: false,
      domicilio: false,
    }
  },

  created () {
    //
  },

  mounted () {
    //
  },

  computed: {
    vencido () {
      return this.orden.estado_id == 1 && this.$moment(this.orden.published_at).isBefore(this.$moment(), 'day')
    },
    selected: {
      get () {
        return this.orden.estado.id
      },

      set (value) {
        this.setEstadoId(value)
      }
    },
    estado: {
      get () {
        return this.edited.estado_id
      },

      set (value) {
        this.setEstadoId(value)
      }
    },
    advertencia: {
      get () {
        return this.orden.advertencia
      },

      set (value) {
        this.setAdvertencia(value)
      }
    },
    ...mapGetters({
      collection: 'Estados/all',
      edited: 'Ordenes/edited',
      loading: 'Ordenes/loading',
    }),
  },

  watch: {
    //
  },

  methods: {
    ...mapActions({
      onFetch: 'Estados/onFetch',
      onSave: 'Ordenes/onUpdate',
    }),
    ...mapMutations({
      setId: 'Ordenes/setId',
      setAdvertencia: 'Ordenes/setAdvertencia',
      setObsEstado: 'Ordenes/setObsEstado',
      setObsChofer: 'Ordenes/setObsChofer',
      setEstadoId: 'Ordenes/setEstadoId',
      setEdited: 'Ordenes/setEdited',
      setChoferId: 'Ordenes/setChoferId',
      setDomicilioId: 'Ordenes/setDomicilioId',
      setPublishedAt: 'Ordenes/setPublishedAt',
    }),
    onSelect (value) {
      this.setId(value.id)
      this.setAdvertencia(value.advertencia)
      this.setObsEstado(value.obs_estado)
      this.setObsChofer(value.obs_chofer)
      this.setEstadoId(value.estado_id)
      this.setChoferId(value.chofer_id)
      this.setPublishedAt(value.published_at)
      this.setDomicilioId(value.domicilio_id)
      this.cliente = value.domicilio.cliente
      this.domicilio = value.domicilio
    },
    onSubmit () {
      this.onSave()
        .then(response => {
          this.dialog = false
          this.$emit('refresh', true)
        })
    }
  },
}
</script>
